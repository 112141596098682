import { combineReducers } from "redux";
import SideMenuReducer from "./side-menu-reducer";
import Loading from './loading-reducer';
import LoginStatus from './login-status';
import FCMReducer from "./fcm-reducer";

const reducers = combineReducers({
    sideMenuStatus: SideMenuReducer,
    loading: Loading,
    loginStatus: LoginStatus,
    fcmReducer: FCMReducer
})

export default reducers;