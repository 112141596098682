import React, { useEffect, useRef, useState } from 'react';
import styles from './delivery.module.scss';
import { IDegreeOfRelavity } from '../../interfaces/degree-of-relavity.interface';
import { getDegreeOfRelavity, orderDeliveryApi } from '../../services/distribution.service';
import PageTitle from '../../components/page-title/page-title';
import { IDelivery } from '../../interfaces/delivery-params.interface';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import DeliveryImage from './delivery-image/delivery-image'
import Header from '../../components/header/header';
import { getCoords } from '../../common/utils';
import DeliveryMap from './delivery-map/delivery-map';
import { ILocation } from '../../interfaces/location.interface';
import SimpleReactValidator from 'simple-react-validator';

const Delivery = () => {

    const navigate = useNavigate();
    const [degrees, setDegrees] = useState<Array<IDegreeOfRelavity>>([]);
    const [description, setDescription] = useState("");
    const [forceUpdate, setForceUpdate] = useState<boolean>(false);
    const [deliveryCode, setDeliveryCode] = useState("");
    const [passport, setPassport] = useState("");
    const [tkc, setTKC] = useState("");
    const [surname, setSurname] = useState("");
    const [Name, setName] = useState("");
    const [relativity, setRelativity] = useState("d226a4b5-fdd5-4529-b0e7-08db6a6ce346");
    const [isNotCitizen, setIsNotCitizen] = useState(false);
    const [imageIds, setImageIds] = useState<Array<string>>([]);
    const [loader, setLoader] = useState(false);
    const [location, setLocation] = useState<ILocation>()
    const { type } = useParams();

    const simpleValidator = useRef(new SimpleReactValidator({
        messages: {
            name: 'Ad alanı zorunludur.',
            surname: 'Soyadı alanı zorunludur.',
            passport: 'passport alanı zorunludur.',
            deliveryCode: 'Temassız Teslimat Kodu alanı zorunludur.',
            tkc: 'TCKN alanı zorunludur.',
        },
    }))


    const getDegrees = async () => {
        const res = await getDegreeOfRelavity();
        setDegrees([...res.data]);
    }

    useEffect(() => {
        setRelativity('d226a4b5-fdd5-4529-b0e7-08db6a6ce346')
    }, [relativity])

    useEffect(() => {
        getDegrees();
    }, [])

    const register = async (e) => {
        e.preventDefault();
        if (simpleValidator.current.allValid()) {
            const storage = localStorage.getItem('delivery-items');

            if (storage) {
                const deliveryItems: IDelivery[] = JSON.parse(storage);
                setLoader(true);
                deliveryItems.map(async (data: IDelivery) => {
                    data.deliveredLastName = surname;
                    data.deliveredDegreeOfRelativityId = relativity;
                    data.deliveredFirstName = Name;
                    data.deliveredDescription = description;
                    data.deliveredIdentityNumber = tkc;
                    data.contactlessDeliveryCode = deliveryCode;
                    data.deliveredPassportNumber = passport;
                    data.deliveredIsNotCitizen = isNotCitizen;
                    if (imageIds.length > 0) {
                        data.files = imageIds;
                    }
                    // data.files = ['5d6d7139-33ea-4c5a-7a0f-08d7771c0158'];
                    data.location = location as ILocation;
                })
                const res = await orderDeliveryApi(deliveryItems);
                setLoader(false);

                if (res.success) {
                    toast.success('Başarıyla yapıldı', {
                        position: "bottom-center",
                    });
                    navigate('/distribution')
                }

            }

        }
        else {
            setForceUpdate(!forceUpdate)
            simpleValidator.current.showMessages();
        }

    }

    const onChangeLocation = (location) => {
        const data: ILocation = { latitude: String(location.lat), longitude: String(location.long) }
        setLocation({ ...data });
    }


    return (
        <>

            <Header title='Teslim' />

            <form onSubmit={e => register(e)}>
                <div className={styles.items}>

                    <div className={styles.item}>
                        <div className={styles.title}>Adı</div>
                        <div className={styles.input}>
                            <input
                                onBlur={() => simpleValidator.current.showMessageFor('name')}
                                type='text' name='name' onChange={(e) => setName(e.target.value)} value={Name} className='form-control' />
                            {simpleValidator.current.message('name', Name, 'required', { messages: { required: 'Ad alanı zorunludur.' } })}
                        </div>
                    </div>


                    <div className={styles.item}>
                        <div className={styles.title}>Soyadı</div>
                        <div className={styles.input}>
                            <input
                                onBlur={() => simpleValidator.current.showMessageFor('surname')}
                                name='surname' onChange={(e) => setSurname(e.target.value)} value={surname} type='text' className='form-control' />
                            {simpleValidator.current.message('surname', surname, 'required', { messages: { required: 'Soyadı alanı zorunludur.' } })}

                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.title}>TCKN</div>
                        <div className={styles.input}>
                            <input
                                name='tkc' onChange={(e) => setTKC(e.target.value)} value={tkc} type='text' className='form-control' />
                            {simpleValidator.current.message('tkc', tkc, 'numeric', { messages: { numeric: 'TC yalniş' } })}

                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.title}>Pasaport No</div>
                        <div className={styles.input}>
                            <input
                                name='passport' onChange={(e) => setPassport(e.target.value)} value={passport} type='text' className='form-control' />
                        </div>
                    </div>


                    <div className={styles.item}>
                        <div className={styles.title}>Temassız Teslimat Kodu</div>
                        <div className={styles.input}>
                            <input
                                name='deliveryCode' onChange={(e) => setDeliveryCode(e.target.value)} value={deliveryCode} type='text' className='form-control' />
                        </div>
                    </div>


                    <div className={styles.item}>
                        <div className={styles.title}>Yakınlık Derecesi</div>
                        <div className={styles.input}>
                            <select className='form-select' value={relativity} onChange={(e) => setRelativity(e.target.value)}>
                                <option value=''></option>

                                {degrees.map(item => {
                                    return (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.title}>Açıklama</div>
                        <div className={styles.input}>
                            <input
                                name='description' onChange={(e) => setDescription(e.target.value)} value={description} type='text' className='form-control' />
                        </div>
                    </div>


                    <div className={`${styles.item} ${styles.signature}`}>
                        <DeliveryImage hasSignature={true} title="İmza" onImageUpload={e => setImageIds(e)} />
                    </div>


                    <div className={styles.item}>
                        <DeliveryMap onLocationChange={e => onChangeLocation(e)} />
                    </div>




                    <div className={`${styles.buttons} ${styles.registerBtns}`}>
                        <button className='pretty-btn btn-outline' onClick={() => navigate(-1)}>Vazgeç</button>
                        <button className='pretty-btn' type='submit'>
                            Teslim E1t
                            {loader ? (
                                <div className='spinner-border text-light'></div>
                            ) : null}
                        </button>
                    </div>


                </div>
            </form>
        </>
    )

}

export default Delivery;