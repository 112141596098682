import React, { useEffect, useRef, useState } from 'react';
import styles from './scanner.module.scss'
declare var Html5Qrcode: any;

const Scanner = (props: any) => {

    const scanner = useRef<any>();
    const [data, setData] = useState();

    useEffect(() => {
        return () => {
            scanner.current.stop();
        }
    }, [])

    const getSize = () => {

        let width = 250;
        let height = 250;
        if (window.matchMedia('(max-width:1440px)').matches) {
            width = 600;
            height = 600;
        }
        if (window.matchMedia('(max-width:1024px)').matches) {
            width = 400;
            height = 400;
        }
        if (window.matchMedia('(max-width:500px)').matches) {
            width = 245;
            height = 245;
        }
        return { width, height }

    }

    useEffect(() => {
        if (props.scan) {
            scanner.current = new Html5Qrcode("reader");
            const qrCodeSuccessCallback = (decodedText, decodedResult) => {
                props.onData(decodedText)
                /* handle success */
            };
            const { width, height } = getSize();
            const config = { fps: 10, qrbox: { width: width, height: height } };

            // If you want to prefer front camera
            scanner.current.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
        }
    }, [props.scan])

    const close = () => {
        props.onClose(true);
    }

    return (
        <>
            <div className={`${props.fullWidth ? styles.fullWidth : ''}`}>
                <div id="reader" className={styles.reader}></div>
                <button className={`btn ${styles.btn}`} onClick={close} >kapatmak</button>
            </div>
        </>
    )

}

export default Scanner;