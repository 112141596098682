
import React from 'react'
import styles from './page-title.module.scss';
import { useNavigate } from 'react-router-dom';

type propsType = {
    title: string
}
const PageTitle = (props: propsType) => {

    const navigate = useNavigate();

    const back = () => {
        navigate(-1)
    }

    return (
        <div className={styles.pageTitle}>
            <i className='fa fa-angle-left' onClick={back}> </i>
            {props.title}
        </div>
    )

}

export default PageTitle;