const LoadingReducer = (state: boolean, action: any) => {
    switch (action?.type) {
        case 'SHOW_LOADING':
            {
                return true;
            }
        case 'HIDE_LOADING':
            {
                return false;
            }
    }

    return false
}

export default LoadingReducer;