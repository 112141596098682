const LoginStatusReducer = (state: boolean, action: any) => {
    switch (action?.type) {
        case 'LOGIN':
            {
                return true;
            }
        case 'LOGOUT':
            {
                return false;
            }
    }

    return false
}

export default LoginStatusReducer;