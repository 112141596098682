import React, { useEffect, useState } from 'react';
import styles from './return.module.scss';
import { getReasonForReturn2, orderReturnApi } from '../../services/distribution.service';
import PageTitle from '../../components/page-title/page-title';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IReturnParams } from '../../interfaces/return-params.interface';
import { IDelivery } from '../../interfaces/delivery-params.interface';
import DeliveryImage from '../delivery/delivery-image/delivery-image';

const Return = () => {

    const navigate = useNavigate();
    const [reasons, setReasons] = useState<any>([]);
    const [reason, setReason] = useState<string>('');
    const [description, setDescription] = useState("");
    const [deliveryCode, setDeliveryCode] = useState("");
    const [loader, setLoader] = useState(false);
    const [imageIds, setImageIds] = useState<Array<string>>([]);



    useEffect(() => {
        getReasons();
    }, [])

    const getReasons = async () => {
        const res: any = await getReasonForReturn2();
        setReasons([...res.data]);
    }

    const register = async () => {

        const storage = localStorage.getItem('return-item');
        if (storage) {
            setLoader(true);
            const data: IDelivery = JSON.parse(storage);
            const params = {} as IReturnParams;
            params.description = description;
            params.orderID = data.orderId;
            params.distributionPlanId = data.distributionPlanId;
            params.barcodeIds = data.deliveredItemBarcodes;
            params.returnReasonId = reason;
            params.contactlessDeliveryCode = deliveryCode;
            params.files = imageIds;
            const res = await orderReturnApi(params);
            setLoader(false);

            if (res.success) {
                navigate('/distribution')
            }
            else {
                toast.error(res.message, {
                    position: "bottom-center",
                });

            }
        }

    }

    return (
        <>

            <PageTitle title='İade' />
            <div className={styles.items}>

                <div className={styles.item}>
                    <div className={styles.title}>İade Sebebi</div>
                    <div className={styles.input}>
                        <select className='form-select' onChange={(e) => setReason(e.target.value)}>
                            <option value=''></option>
                            {reasons.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.title}>Açıklama</div>
                    <div className={styles.input}>
                        <input onChange={(e) => setDescription(e.target.value)} value={description} type='text' className='form-control' />
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.title}>Temassız Teslimat Kodu</div>
                    <div className={styles.input}>
                        <input type='text' onChange={(e) => setDeliveryCode(e.target.value)} value={deliveryCode} className='form-control' />
                    </div>
                </div>


                <div className={styles.item}>
                    <DeliveryImage title="Fotoğraf" hasSignature={false} onImageUpload={e => setImageIds(e)} />
                </div>




                <div className={styles.buttons}>
                    <button className='pretty-btn' onClick={() => navigate('/distribution')}>Vazgeç</button>
                    <button className='pretty-btn' onClick={register}>
                        İade Et
                        {loader ? (
                            <div className='spinner-border text-light'></div>
                        ) : null}
                    </button>
                </div>


            </div>
        </>
    )

}

export default Return;