
import { useSelector } from 'react-redux';
import styles from './loading.module.scss';
import { useEffect } from 'react';
const Loading = () => {
    const loading = useSelector((a: any) => a.loading)

    useEffect(() => {
        console.log(loading)
    }, [loading])
    return (
        <>
            <div className={`${styles.loading} ${loading ? styles.show : 'hide'}`} >
                <div className='spinner-border text-danger'></div>
            </div>
        </>
    )
}

export default Loading;