import React, { useEffect, useRef, useState } from 'react';
import styles from './single-collection-items.module.scss';
import { IDistributionList } from '../../../interfaces/distribution-list.interface';
import moment from 'moment'
import { IDelivery } from '../../../interfaces/delivery-params.interface';
import { Navigate, useNavigate } from 'react-router-dom';
import { handOver ,unassignPreOrder} from '../../../services/distribution.service';
import { toast } from 'react-toastify';
import { batchAddOrder } from '../../../services/pre-order.service';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/actions';



type props = {
    list: any,
    addressId: string,
    reload: (e: boolean) => void;
    onSelectedList: (e: Array<any>) => void;
    selectAll: boolean;
}
declare var bootstrap: any;
const SingleCollectionItems = (props: props) => {

    const [list, setList] = useState<any>([]);
    const [selectedList, setSelectedList] = useState<any>([]);
    const [showActions, setShowActions] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>({} as any);
    const modalRef = useRef<any>();
    const unAssignModalRef = useRef<any>();
    const [reason, setReason] = useState<string>("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setSelectedList([])
        setList([...props.list]);
    }, [props.list])

    useEffect(() => {
        if(props.selectAll){
            const items:any = [];
            props.list.map(item => {
                items.push(item)
            })
            setSelectedList([...items])
        }
        else{
            setSelectedList([])
        }
    }, [props.selectAll])

    useEffect(() => {
        console.log(selectedList)
        props.onSelectedList(selectedList)
    }, [selectedList])

    useEffect(() => {
        setTimeout(() => {
            modalRef.current = new bootstrap.Modal(document.getElementById('devir'), {
                keyboard: false
            })
        }, 100)
    }, [])

  useEffect(() => {
    setTimeout(() => {
      unAssignModalRef.current = new bootstrap.Modal(
        document.getElementById("unassign"),
        {
          keyboard: false,
        }
      );
    }, 100);
  }, []);

    const toggleContent = (index: number) => {
        list[index].openContent = !list[index].openContent;
        setList([...list])
    }

    const onSelect = (item) => {
        setShowActions(true);
        setSelectedItem({ ...item });
    }

    const batchOrder = async() => {
        dispatch(showLoading())
        const params = {} as any;
        params.preOrderId = selectedItem.id;
        params.preOrderCode  = selectedItem.code;
        params.senderCustomerName  = selectedItem.senderName;
        params.preOrderBarcodes = [];
        params.isRequestFromMobile  = true;
        params.isPNR = false;
        params.addPNROrderFromPNRPreOrder = null;
        selectedItem.barcodes.map(item => {
            const data = {} as any;
            data.no = item.code;
            data.senderAddressId = props.addressId;
            data.status = item.status;
            data.preOrderId = item.preOrderId;
            data.preOrderItemDetailId = item.preOrderItemDetailId;
            data.preOrderItemId = item.preOrderItemId;
            params.preOrderBarcodes.push(data)
        })
        const res = await batchAddOrder([params]);
        setShowActions(false);
        props.reload(true);
        dispatch(hideLoading())
        toast.success('Başarlı Teslim Alındı', {
            position: "bottom-center",
        });
    }

  const openUnAssingModal = () => {
    unAssignModalRef.current.show();
  };

  const closeUnAssingModal = () => {
    unAssignModalRef.current.hide();
  };

  const handleUnAssign = async () => {
    setLoader(true);
    const res = await unassignPreOrder(selectedItem?.collectionPlanId, reason);
    setLoader(false);
    if (res.success === true) {
      toast.success("işlemi başarıyla gerçekleşti.", {
        position: "bottom-center",
      });
    } else {
      toast.error(res.message, {
        position: "bottom-center",
      });
    }

    closeUnAssingModal();
    setShowActions(false);
    props.reload(true);
  };

    const setReturnData = () => {
        const data = {} as IDelivery;
        data.orderId = selectedItem.orderId;
        data.distributionPlanId = selectedItem.id;
        data.deliveredItemBarcodes = selectedItem.barcodes.map(item => item.id);
        localStorage.setItem('return-item', JSON.stringify(data));
        navigate('/return')
    }

    const openModal = () => {
        modalRef.current.show()
    }

    const closeModal = () => {
        modalRef.current.hide()
    }

    const devir = async () => {
        setLoader(true)
        const res = await handOver(selectedItem.id);
        setLoader(false)
        if (res.success === true) {
            toast.success('Devir işlemi başarıyla gerçekleşti.', {
                position: "bottom-center",
            });

        } else {
            toast.error(res.message, {
                position: "bottom-center",
            });
        }

        closeModal();
        setShowActions(false);
        props.reload(true);

    }


    const cancel = () => {
        setShowActions(false);
        closeModal();
    }

 

    const checkSelectedItems = (item) => {
        const index = selectedList.findIndex(a => a.orderId === item.orderId);
        return index > -1;
    }

    useEffect(() => {
        localStorage.setItem('delivery-items', JSON.stringify(selectedList));
        props.onSelectedList(selectedList)
    }, [selectedList])

    const select = (e, item) => {
      e.stopPropagation();
      const index = selectedList.findIndex(a => a.id === item.id);
      if (index === -1) {
          selectedList.push(item)
          setSelectedList([...selectedList])
      }
      else {
          selectedList.splice(index, 1);
          setSelectedList([...selectedList])
      }
      
    }

    const checkChecked = (item):boolean => {
        const index = selectedList.findIndex(a => a.id === item.id);
        return index > -1 ? true: false;
    }
    

    return (
        <>

            <div className={styles.items}>

                {list?.map((item, index) => {
                    return (
                        <div key={index} className={`${styles.item} box-item`}>


                            <div className={styles.itemTitle} onClick={() => toggleContent(index)}>
                                {/* {checkSelectedItems(item) ? (
                                    <i className='fa fa-check text-success me-3'></i>
                                ) : null} */}
                                <input checked={checkChecked(item)} type='checkbox' onClick={(e) => select(e, item)} />

                                <div className={styles.titleLeft}>
                                    <span>{item.code}</span>
                                    <span>{moment(item.createDateTime).format('DD/MM/YYYY')}</span>
                                    <span>{item.productName}</span>
                                </div>
                                <i className={`fa fa-angle-down fa-lg ${styles.angle}`} ></i>
                            </div>

                            <div className={`${styles.itemContent} ${item.openContent ? 'show' : styles.hide}`} >

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Gönderen</div>
                                    <div className={styles.value}>{item?.senderName}</div>
                                </div>

                                    <div className={styles.contentItem}>
                                        <div className={styles.key}>Alım yeri</div>
                                        <div className={styles.value}>{item.differentSenderName ? item.differentSenderName : '-'}</div>
                                    </div>
                                <div className={styles.contentItem}>
                                    <div className={styles.key}>Barkodlar</div>
                                    <div className={styles.value}>
                                        {item?.barcodes?.map((bItem, bIndex) => {
                                            return (
                                                <>
                                                    <div key={bIndex}>{bItem.code} ({bItem.preorderNoAttribute})</div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className={styles.button} onClick={() => onSelect(item)}>
                                    <button className={`btn`}>İşlem</button>
                                </div>

                            </div>



                        </div>
                    )
                })}

            </div>

            {showActions ? (
                <>
                    <div className={styles.backDrop} onClick={() => setShowActions(false)}></div>

                    <div className={styles.actions}>
                        <div className={styles.actionTitle}>Yapmak istediğiniz işlemi seçiniz</div>
                        <div className={styles.actionItem} onClick={batchOrder}>
                            <img src="./assets/images/tick.svg" alt='Teslim1' />
                            <span>Teslim AL</span>
                        </div>

                        <div className={styles.actionItem} onClick={openUnAssingModal}>
                            <img src="./assets/images/refresh.svg" alt="refresh" />
                            <span>Atama Kaldır</span>
                        </div>
                    </div>
                </>

            ) : null}


            <div className="modal fade " id="devir" role="dialog">
                <div className="modal-dialog modal-dialog-centered " role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className={styles.modalTitle}>
                                Uyarı
                            </div>
                            <div className={styles.modalBody}>
                                Bu gönderiyi devir etmek istediğinizden emin misiniz?
                            </div>
                            <div className={styles.modalButtons}>
                                <button className='pretty-btn' data-bs-dismiss="modal" >Hayır</button>
                                <button className='pretty-btn' onClick={devir}>
                                    Evet
                                    {loader ? (
                                        <div className='spinner-border text-danger'></div>
                                    ) : null}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade " id="unassign" role="dialog">
                <div className="modal-dialog modal-dialog-centered " role="document">
                <div className="modal-content">
                    <div className="modal-body">
                    <div className={styles.modalTitle}>Atama Kaldır</div>
                    <div className={styles.modalBody}>Sebeb:</div>
                    <textarea
                        onChange={(e) => setReason(e.target.value)}
                        className="form-control"
                        name=""
                        id=""
                    ></textarea>

                    <div className={styles.modalButtons}>
                        <button className="pretty-btn" data-bs-dismiss="modal">
                        Hayır
                        </button>
                        <button className="pretty-btn" onClick={handleUnAssign}>
                        Evet
                        {loader ? (
                            <div className="spinner-border text-danger"></div>
                        ) : null}
                        </button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )

}

export default SingleCollectionItems;