import React, { useEffect, useRef, useState } from 'react';
import styles from './embezzlement.module.scss';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import Scanner from '../scanner/scanner';
import { EmbezzlementOfBarcode, getEmbezzlement } from '../../services/embezzlement.service';
import EmbezzlementHeader from './embezzlement-header/embezzlement-header';
import { IEmbezzlementList } from '../../interfaces/embezzlement-list.interface';
import { ICustomBarcodes } from '../../interfaces/custom-barcodes.interface';
import Header from '../../components/header/header';


const Embezzlement = () => {

    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [orderGroup, setOrderGroup] = useState<Array<IEmbezzlementList>>([])
    const [loader, setLoader] = useState<boolean>(false);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const barcodeRef = useRef<any>();

    const search = async (barcode?: string) => {

        if (barcodeNumber || barcode) {
            const inputBarcode: any = barcode ? barcode : barcodeNumber;
            setScan(false);
            setLoader(true)
            const res = await getEmbezzlement(inputBarcode);
            setLoader(false)
            const order = res.data.data;
            if (order) {
                setToStrage(inputBarcode);
                addOrderGroup(order[0]);
                audioSuccessRef.current.play();
                doneReadedBarcodes([order[0]]);


            }
            else {
                audioErrorRef.current.play();
                toast.error(res.data.message, {
                    position: "bottom-center",
                });

            }

            setBarcodeNumber('');
            barcodeRef.current?.focus();
            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }

    const addOrderGroup = (order: IEmbezzlementList) => {

        const index = orderGroup.findIndex(item => item.orderCode === order.orderCode);
        if (index === -1) {
            const orders = orderGroup;
            orders.push(order);
            checkVisitedBarcodes(orders)
        }
        else {
            checkVisitedBarcodes(orderGroup)
        }

    }


    const checkVisitedBarcodes = (orders: Array<IEmbezzlementList>) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('embezzlement-barcodes') as string;

        if (storage) {
            barcodeList = JSON.parse(storage);

            orders = orders.map(order => {
                order.customBarcodes = generateCustomBarcodes(order);
                order.readed = [];
                order.customBarcodes = order.customBarcodes.map(barcodeItem => {
                    const index = barcodeList.findIndex(a => a === barcodeItem.no);
                    if (index > -1) {
                        barcodeItem.visited = true;
                        order.readed.push(barcodeItem);
                        //  allBarcodesReaded(order)
                    }

                    return barcodeItem;

                })

                return order;
            })


            setOrderGroup([...orders]);

        }


    }

    const generateCustomBarcodes = (order: IEmbezzlementList) => {
        const customBarcodes: Array<ICustomBarcodes> = [];
        order?.barcodes.map(item => {
            const data = {} as ICustomBarcodes;
            data.no = item;
            data.visited = false;
            customBarcodes.push(data);
        })
        return customBarcodes;
    }

    const allBarcodesReaded = (order: IEmbezzlementList) => {
        if (order.readed.length === order.customBarcodes.length) {
            doneReadedBarcodes([order]);
        }
    }

    const doneReadedBarcodes = async (order: Array<IEmbezzlementList>) => {
        const res = await EmbezzlementOfBarcode(order);
        if (res.data.success) {
            toast.success('İşlem Başarılı.', {
                position: "bottom-center",
            })
        }
        else {
            toast.error(res.data.message, {
                position: "bottom-center",
            });
        }
    }

    const setToStrage = (barcode) => {
        let barcodeList: Array<string> = [];
        let storage: string = localStorage.getItem('embezzlement-barcodes') as string;
        if (storage) {
            barcodeList = JSON.parse(storage);
            const index = barcodeList.findIndex(a => a === barcode);
            if (index === -1) {
                barcodeList.push(barcode);
            }
        }
        else {
            barcodeList.push(barcode);
        }

        localStorage.setItem('embezzlement-barcodes', JSON.stringify(barcodeList))

    }






    const toggle = (item: any) => {
        const index = orderGroup.findIndex(a => a.orderCode === item.orderCode);
        orderGroup[index].openContent = orderGroup[index].openContent ? !orderGroup[index].openContent : true;
        setOrderGroup([...orderGroup])
    }

    const generateOrderGroups = (readed: boolean = false) => {
        let order = orderGroup.filter(a => a.readed?.length !== a.customBarcodes?.length);;
        if (readed) {
            order = orderGroup.filter(a => a.readed?.length === a.customBarcodes?.length);
        }

        return order.map((item, index) => {
            return (
                <>
                    <div key={item.orderCode} className={`${styles.list} box-item`}>

                        <div className={styles.item}>

                            <div className={`${styles.title} ${!item.openContent ? styles.noBorder : null}`} onClick={e => toggle(item)}>
                                <span>seperis No: {item?.orderCode}</span>
                                <i className='fa fa-angle-down'></i>
                            </div>

                            {item.openContent ? (
                                <div className={styles.content} >
                                    <div className={styles.barcodeLength}>
                                        Okutulan / Toplam Barkod: {item.readed?.length}/{item?.customBarcodes?.length}
                                    </div>


                                    {item?.customBarcodes?.map(barcode => {
                                        return (
                                            <div key={barcode.no} className={`${styles.items} ${barcode?.visited ? 'text-success' : 'text-danger'}`}>
                                                {barcode.no}
                                            </div>
                                        )
                                    })}

                                    <div className={styles.info}>
                                        <div className={styles.Key}> desiKg</div>
                                        <div className={styles.Value}> {item?.desiKg} </div>
                                    </div>

                                    <div className={styles.info}>
                                        <div className={styles.Key}> Paket Tipi</div>
                                        <div className={styles.Value}> {item?.packageType} </div>
                                    </div>

                                </div>
                            ) : null}



                        </div>

                    </div>


                </>

            )
        })

    }

    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }


    return (
        <>

            <Header title="Toplu Zimmet Oluştur" />


            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className={styles.group}>

                <div className={styles.barcode}>
                    <input
                        ref={barcodeRef}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}
                        value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
                    <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Ara2</button>
                    <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                        <Icon path={mdiBarcode}
                            title={''}
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color={'black'}
                        // spin
                        />
                    </button>


                </div>



                {scan ? (
                    <div className={styles.video}>
                        <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                    </div>
                ) : null}


                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}



                {orderGroup.filter(a => a?.readed?.length !== a?.customBarcodes?.length).length > 0 ? (
                    <div className={styles.groupSection}>

                        <div className={styles.groupTitle}>
                            Okutuldu
                        </div>
                        <div className={styles.part}>

                            {generateOrderGroups()}
                        </div>
                    </div>
                ) : null}

                {orderGroup.filter(a => a.readed?.length === a.customBarcodes?.length).length > 0 ? (
                    <>

                        <div className={styles.groupSection}>
                            <div className={styles.groupTitle}>
                                Zimmet alındı
                            </div>
                            <div className={styles.part}>

                                {generateOrderGroups(true)}

                            </div>
                        </div>

                    </>
                ) : null}


            </div >

        </>
    )

}

export default Embezzlement;