import React, { useEffect, useRef, useState } from 'react';
import styles from './load-items.module.scss';
import moment from 'moment'
import { getVoyagesByCourseId } from '../../../services/load-unload.service';
import { useNavigate } from 'react-router-dom';
import { ITransferList } from '../../../interfaces/transfer-list.interface';


type props = {
    list: Array<any>,
}
declare var bootstrap: any;

const LoadItems = (props: props) => {
    const [list, setList] = useState<Array<ITransferList>>([]);
    const navigate = useNavigate();
    useEffect(() => {
        setList([...props.list]);
    }, [props.list])

    const toggleContent = (index: number) => {
        list[index].openContent = !list[index].openContent;
        setList([...list])
    }

    const toggleVoyage = (index: number, vIndex: number) => {
        list[index].voyages[vIndex].openContent = !list[index].voyages[vIndex].openContent;
        setList([...list])

    }

    const getVoyages = async (item: any, index) => {
        list[index].loading = true;
        setList([...list])
        const res = await getVoyagesByCourseId(item.id);
        list[index].loading = false;
        list[index].voyages = res;
        setList([...list])
    }


    const getLoad = (item) => {
        localStorage.setItem('transfer', JSON.stringify(item))
        navigate('/load')
    }




    return (
        <>

            <div className={styles.items}>

                {list?.map((item, index) => {
                    return (
                        <div key={item.id} className={`${styles.item} box-item`}>


                            <div className={styles.itemTitle} onClick={() => toggleContent(index)}>
                                <span>{item.courseCodeAndName}</span>
                                <i className={`fa fa-angle-down fa-lg ${styles.angle}`} ></i>
                            </div>

                            <div className={`${styles.itemContent} ${item.openContent ? 'show' : styles.hide}`} >


                                <div className={styles.contentItem}>
                                    <div className={styles.key}>  Güzergah Kodu/Adı:</div>
                                    <div className={styles.value}> {item?.courseCodeAndName}</div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>    Sefer Barkodu:</div>
                                    <div className={styles.value}> {item?.barcode}</div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}>     Güzergah Barkodu:</div>
                                    <div className={styles.value}> {item?.routeBarcode}</div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}> Güzergah Tipi:</div>
                                    <div className={styles.value}> {item?.routeType}</div>
                                </div>

                                <div className={styles.contentItem}>
                                    <div className={styles.key}> Yükleme Tipi:</div>
                                    <div className={styles.value}>
                                        {item.loadingType}
                                    </div>
                                </div>

                                <div className={styles.contentItem}>
                                    <button className='pretty-btn col-12 fw-bold' onClick={() => getLoad(item)} >
                                        Yukleme
                                    </button>
                                </div>


                            </div>



                        </div>
                    )
                })}

            </div >











        </>
    )

}

export default LoadItems;