import React, { useEffect, useRef, useState } from 'react';
import Styles from './load.module.scss'
import { ITransferList } from '../../../interfaces/transfer-list.interface';
import Header from '../../../components/header/header';
import BarcodeList from '../barcode-list/barcode-list';
import { ITransferBarcode } from '../../../interfaces/transfer-barcode.interface';
import { useNavigate } from 'react-router-dom';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import Scanner from '../../scanner/scanner';
import { toast } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2'


const Load = () => {
    const [activeTransfer, setActiveTransfer] = useState<ITransferList>({} as ITransferList)
    const [transferBarcodes, setTransferBarcodes] = useState<ITransferBarcode[]>([]);
    const transferBarcodesRef = useRef<ITransferBarcode[]>([]);
    const [shouldLoadedBarcodes, setShouldLoadedBarcodes] = useState<ITransferBarcode[]>([]);
    const shouldLoadedBarcodesRef = useRef<ITransferBarcode[]>([]);
    const barcodeRef = useRef<any>('')

    const [scan, setScan] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [barcode, setBarcode] = useState('')
    const [removeBarcode, setRemoveBarcode] = useState<string>('')
    const socket = useRef<any>();
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const navigate = useNavigate();

    useEffect(() => {
        const transfer = JSON.parse(localStorage.getItem('transfer') as string)
        setActiveTransfer({ ...transfer })
        barcodeRef.current.focus();

        return () => {
            closeSocket();
        }
    }, [])


    useEffect(() => {
        if (activeTransfer.id) {
            loadSocket();
        }

    }, [activeTransfer])


    const closeSocket = () => {
        const closeStream = { "arguments": [activeTransfer.id], "target": "UnSubscribeStream", "type": 1 }
        if (socket.current) {
            sendMessage(closeStream);
            socket.current.close();
        }
    }


    const loadSocket = () => {
        console.log(process.env)
        const token = localStorage.getItem('token')
        socket.current = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}MainHub?access_token=${token}`)
        socket.current.onopen = () => {
            console.log('opended')
            const firstMessage = { "protocol": "json", "version": 1 }
            sendMessage(firstMessage)
            const loadMessage = { "arguments": [activeTransfer.id], "target": "SubscribeTravelStream", "type": 1 }
            sendMessage(loadMessage)
        }
        socket.current.onerror = (e: any) => {
            console.log('error', e)
        }

        socket.current.onmessage = (data: any) => {
            const message = JSON.parse(data.data.replace('', ''));
            if(message?.arguments?.[0].subscriptionId == activeTransfer.id){
                setLoading(false)
              }
            if (message?.arguments && message.arguments.length > 0 && !message?.arguments[0]?.success) {

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn",
                        cancelButton: "btn btn-danger"
                    },
                    buttonsStyling: false
                });
                swalWithBootstrapButtons.fire({
                    icon: "error",
                    title: "Hata...",
                    text: message.arguments[0].message,
                    confirmButtonText: "Tamam",
                    confirmButtonColor: "#fd7e14",
                });
                playSound('errorSound')
            }
            else if (message.arguments && message.arguments.length > 0 && message.arguments[0].subscriptionId == activeTransfer.id) {

                if (message.target == 'GetTransferBarcode') {
                    setTransferBarcodes([...message.arguments[0].data]);
                    transferBarcodesRef.current = message.arguments[0].data;
                }
                else if (message.target == 'BarcodeShouldBeLoad') {
                    setShouldLoadedBarcodes([...message.arguments[0].data]);
                    shouldLoadedBarcodesRef.current = message.arguments[0].data;
                }
                else if (message.target == 'ABarcodeUndoLoaded') {
                    const undoBarcode = message.arguments[0].data.barcode;
                    const index = transferBarcodesRef.current.findIndex(a => a.barcode == undoBarcode)
                    transferBarcodesRef.current.splice(index, 1)
                    setTransferBarcodes([...transferBarcodesRef.current])
                }
                else if (message.target == 'BarcodeShouldBeLoadRemove') {
                    const undoBarcodes: ITransferBarcode[] = message.arguments[0].data;
                    undoBarcodes.map(item => {
                        const index = shouldLoadedBarcodesRef.current.findIndex(a => a.barcode == item.barcode);
                        shouldLoadedBarcodesRef.current.splice(index, 1);
                    })
                    setShouldLoadedBarcodes([...shouldLoadedBarcodesRef.current])
                }
                else if (message.target == 'BarcodeShouldBeLoadAdd') {
                    const barcodeShouldAdd: ITransferBarcode[] = message.arguments[0].data;
                    barcodeShouldAdd.map(item => {
                        const index = shouldLoadedBarcodesRef.current.findIndex(a => a.barcode == item.barcode);
                        if (index == -1) {
                            item.isNew = true
                            shouldLoadedBarcodesRef.current.unshift(item);
                        }
                    })
                    setShouldLoadedBarcodes([...shouldLoadedBarcodesRef.current])

                    setTimeout(() => {
                        shouldLoadedBarcodesRef.current.map(item => item.isNew = false)
                        setShouldLoadedBarcodes([...shouldLoadedBarcodesRef.current])
                    }, 1000)
                }
                else if (message.target == 'ABarcodeLoaded') {
                    const loadedBarcode: ITransferBarcode = message.arguments[0].data;

                    ///remove from shoudloadedbarcodes 
                    const shouldLoadedIndex = shouldLoadedBarcodesRef.current.findIndex(a => a.barcode == loadedBarcode.barcode)
                    if (shouldLoadedIndex > -1) {
                        shouldLoadedBarcodesRef.current.splice(shouldLoadedIndex, 1)
                        setShouldLoadedBarcodes([...shouldLoadedBarcodesRef.current])
                    }

                    const index = transferBarcodesRef.current.findIndex(a => a.barcode == loadedBarcode.barcode);
                    if (index == -1) {
                        transferBarcodesRef.current.map(item => item.isNew = false)
                        loadedBarcode.isNew = true;
                        transferBarcodesRef.current.unshift(loadedBarcode)
                        setTransferBarcodes([...transferBarcodesRef.current])
                        playSound('successSound')
                    }
                    else {
                        playSound('errorSound')
                    }
                    setTimeout(() => {
                        transferBarcodesRef.current.map(item => item.isNew = false);
                        setTransferBarcodes([...transferBarcodesRef.current])
                    }, 1000)
                }

            }

        }
    }


    const sendMessage = (message: any) => {
        socket.current.send(JSON.stringify(message) + '')
    }


    const playSound = (id: string) => {
        (document.getElementById(id) as HTMLAudioElement).pause();
        (document.getElementById(id) as HTMLAudioElement).currentTime = 0;
        (document.getElementById(id) as HTMLAudioElement).play();
    }

    const load = (_barcode?: string) => {
        const inputBarcode: any = _barcode ? _barcode : barcode;

        if (inputBarcode == 'k001') {
            navigate('/load-unload');
        }
        const loadMessage = { "arguments": [activeTransfer.id, inputBarcode], "target": "Load", "type": 1 }
        sendMessage(loadMessage);
        setBarcode('')
        const focus = (document.getElementById('barcode') as HTMLInputElement).focus();
        setLoading(true)
    }


    const onScannerSuccess = (_barcode: string) => {
        setBarcode(_barcode);
        load(_barcode);
        setScan(false);
    }


    const deleteBarcode = () => {
        const barcode = removeBarcode;
        const loadMessage = { "arguments": [activeTransfer.id, barcode], "target": "undoLoad", "type": 1 }
        sendMessage(loadMessage);
        setOpenRemoveModal(false)
        setRemoveBarcode('');
    }


    const onCloseModel = () => {
        setOpenRemoveModal(false)
    }

    const calculatePercentOfLoadedBarcodes = () => {
        return (transferBarcodes.length / (shouldLoadedBarcodes.length +
            transferBarcodes.length) * 100)
    }

    return (
        <>

            <Header title="Yükleme" />

            <audio controls ref={audioSuccessRef} id="successSound">
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef} id="errorSound">
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className='page-content '>

                <div className={Styles.transferStatistics}>

                    <div className={Styles.items}>
                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                Yüklenmesi Gereken Parça Adedi
                            </div>
                            <div className={Styles.value}>{shouldLoadedBarcodes.length}</div>
                        </div>

                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                Yüklenen Parça Adedi

                            </div>
                            <div className={Styles.value}>{transferBarcodes.length}</div>
                        </div>

                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                Yükleme Tamamlanma Oranı

                            </div>
                            <div className={`progress col-12 ${Styles.progressBar}`}>
                                <div className="progress-bar progress-bar-animated progress-bar-striped bg-success" role="progressbar" style={{ width: `${calculatePercentOfLoadedBarcodes()}%` }} ></div>
                            </div>
                            {!isNaN(calculatePercentOfLoadedBarcodes()) ? (
                                <div className={Styles.value}>
                                    {calculatePercentOfLoadedBarcodes().toFixed(0)}%
                                </div>
                            ) : 0}


                        </div>
                    </div>




                </div>

                <div className={Styles.title}>
                    Kargo Bilgileri - <span className="text-primary ms-2">Yükleme</span>
                </div>

                <div className={Styles.sliderBox}>


                    <div className={Styles.item}>
                        <div className={Styles.key}>
                            Plaka Numarası
                        </div>
                        <div className={`${Styles.value} text-primary`}>
                            {activeTransfer.plateNumber}
                        </div>
                    </div>

                    <div className={Styles.item}>
                        <div className={Styles.key}>
                            Sefer Kodu
                        </div>
                        <div className={`${Styles.value} text-primary`}>
                            {activeTransfer.code}

                        </div>
                    </div>

                    <div className={Styles.item}>
                        <div className={Styles.key}>
                            Güzergah Kodu/Adı
                        </div>
                        <div className={`${Styles.value} text-primary`}  >
                            {activeTransfer.courseCodeAndName}
                        </div>
                    </div>




                </div>

                <div className={Styles.barcodePart}>


                    <div className={Styles.form}>
                        <div className={Styles.input}>
                            <div className="label">Barkod:</div>
                            <input
                                readOnly={loading}
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        load();
                                    }
                                }}
                                id="barcode" value={barcode} ref={barcodeRef} onChange={e => setBarcode(e.target.value)} className="form-control" />
                            <div className="btn-group">
                                <button className="btn btn-sm" type="submit" onClick={() => load()}>Yükle</button>
                                <button className="btn btn-sm" type="submit" onClick={() => setOpenRemoveModal(true)}>Sill</button>
                                <button onClick={() => { setScan(!scan) }} className={`${Styles.barcodeImage} btn btn-sm btn-outline`} type='button'>
                                    <Icon path={mdiBarcode}
                                        title={''}
                                        size={1}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color={'black'}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                {scan ? (
                    <div className={Styles.video}>
                        <Scanner onClose={() => { setScan(false) }} scan={scan} onData={(e) => { onScannerSuccess(e) }} />
                    </div>
                ) : null}


                <div className={Styles.box}>
                    <div className={`${Styles.items} box-item`}>
                        <div className={Styles.itemTitle}>Yüklenecek Barkodlar</div>
                        <div className={Styles.itemContent}>
                            <BarcodeList barcodes={shouldLoadedBarcodes} />
                        </div>
                    </div>
                    <div className={`${Styles.items} box-item`}>
                        <div className={Styles.itemTitle}>Yüklenen Barkodlar</div>
                        <div className={Styles.itemContent}>
                            <BarcodeList barcodes={transferBarcodes} />
                        </div>
                    </div>
                </div>






                <Modal
                    classNames={{
                        modal: 'customModal',
                    }}
                    aria-labelledby="my-modal-title"
                    open={openRemoveModal} onClose={onCloseModel} center>
                    <h2 id="my-modal-title">Sill</h2>

                    <div className='col-12 d-flex align-items-center mt-4' >
                        <div className='col-3'>Barkod</div>
                        <div className='col-9'>
                            <input
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        deleteBarcode();
                                    }
                                }}
                                type='text' className='form-control' value={removeBarcode} onChange={e => setRemoveBarcode(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-12 d-flex mt-4 justify-content-center'>
                        <button className='pretty-btn btn-danger' onClick={deleteBarcode}>Sill</button>
                    </div>
                </Modal>



            </div >
        </>
    )

}


export default Load;