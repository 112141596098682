
import axios from "axios";
import { createHeaders, settings } from "../common/utils";
import { IApiBaseResult } from "../interfaces/api-base-result.interface";
import { ICourseFilter } from "../interfaces/course-filter.interface";
import { ITransferParams } from "../interfaces/transfer-params-interface";
import { IAddVoyage } from "../pages/load-unload/interfaces/add-voyage-params";


export const getCourses = async (): Promise<any> => {
    let url: string =
        settings().baseUrl + 'Course/GetAllCoursesWithDetailsForMobile';
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}
export const getCoursesWithFilter = async (model: ICourseFilter): Promise<any> => {
    let name = model.name ? model.name : '';
    let codeOrBarcode = model.codeOrBarcode ? model.codeOrBarcode : '';
    let url: string =
        settings().baseUrl +
        'Course/GetByNameOrCodeOrBarcode?name=' +
        name +
        '&codeOrBarcode=' +
        codeOrBarcode;
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}

export const getVoyagesByCourseId = async (courseId: string): Promise<any> => {
    let url: string =
        settings().baseUrl +
        'Voyage/GetVoyagesForMobile?CourseId=' + courseId;
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}


export const getTransfers = async (params?: ITransferParams) => {
    let _params = {} as any;
    _params.pageSize = params?.pageSize ?? 100;
    _params.pageNumber = params?.pageNumber ?? 1;
    _params.plateNumber = params?.plateNumber ?? '';
    _params.transferCode = params?.transferCode ?? '';
    _params.routeId = params?.routeId ?? '';
    _params.routeBarcode = params?.routeBarcode ?? '';
    let url: string = settings().serviceUrl + 'CargoTransfer';
    let headers = await createHeaders();
    let response = await axios.get(url, { params: _params, headers: headers });
    return response.data;
}


export const getVoyages = async (): Promise<any> => {
    let url: string =
        settings().serviceUrl +
        'Route/search/code-name';
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}


export const getListAddVoyage = async (courseId: string, courseBarcode: string) => {
    let url = ''
    if (courseBarcode) {
        url = `${settings().serviceUrl}voyage/listaddvoyage?courseBarcode=${courseBarcode}`
    }
    else if (courseId) {
        url = `${settings().serviceUrl}voyage/listaddvoyage?courseid=${courseId}`
    }
    else if (courseBarcode && courseId) {
        url = `${settings().serviceUrl}voyage/listaddvoyage?courseid=${courseId}&courseBarcode=${courseBarcode}`
    }
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}

export const saveVoyage = async (params: IAddVoyage) => {
    let url: string = `${settings().serviceUrl}voyage`
    //let url: string = ` https://hw0m914j-5279.euw.devtunnels.ms/api/voyage`
    let headers = await createHeaders();
    let response = await axios.post(url, params, { headers: headers });
    return response.data;
}


export const getRoomByBarcode = async (barcode: string) => {
    let url: string = `${settings().serviceUrl}room/get-by-barcode?barcode=${barcode}`
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response.data;
}





