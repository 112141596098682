import { settings, createHeaders } from '../common/utils';
import { IExpertizeOrderDetail } from '../pages/expertise/interfaces/expertize-order-detail';
import { IExpertizeRegisterOrderParmeters } from '../pages/expertise/interfaces/expertize-register-order-parameters';
import axios from './../common/axios';


export const getExpertizeDetailByBarcode = async (barcode: string): Promise<any> => {
    let url: string =
        settings().serviceUrl +
        `Expertise/detail-by-barcode?barcode=${barcode} `;
    let headers = await createHeaders();
    let response = await axios.get(url, { headers: headers });
    return response;
}

export const updateExpertize = async (params: IExpertizeRegisterOrderParmeters) => {
    let url: string =
        settings().serviceUrl +
        `Expertise`;
    let headers = await createHeaders();
    let response = await axios.post(url, params, { headers: headers });
    return response;
}

