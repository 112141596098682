
import React from 'react';
import styles from './collection.module.scss';
import Group from './group/group';
import Header from '../../components/header/header';

const Collection = () => {


    return (
        <>
            <Header title="Toplu Sipariş Oluştur" />
            <div className={styles.contents}>
                <Group />
            </div>


        </>
    )

}


export default Collection;