import React, { useEffect, useState } from 'react';
import styles from './load-filters.module.scss';
import { ICourseFilter } from '../../../interfaces/course-filter.interface';
import { ITransferParams } from '../../../interfaces/transfer-params-interface';
import Select from 'react-select'
import { getVoyages } from '../../../services/load-unload.service';
import { IOptions } from '../../../interfaces/options.interface';


const LoadFilters = (props: any) => {

    const [openContent, setOpenContent] = useState<boolean>(false);
    const [routeBarcode, setRouteBarcode] = useState<string>('');
    const [routeId, setRouteId] = useState<string>('');

    const [voyages, setVoyages] = useState<any>([]);


    useEffect(() => {
        _getVoyages();
    }, [])


    const _getVoyages = async () => {
        const res = await getVoyages();
        const options: IOptions[] = res.items.map(item => {
            return {
                value: item.id,
                label: item.name
            }
        });
        setVoyages([...options])
    }

    const filter = () => {
        const data = {} as ITransferParams;
        data.routeBarcode = routeBarcode;
        data.routeId = routeId;
        console.log(data)
        props.onFilter(data);
    }

    const reset = () => {
        setRouteBarcode('');
        setRouteId('');
        const data = {} as ITransferParams;
        data.routeBarcode = '';
        data.routeId = '';
        props.onFilter(data);
    }

    return (
        <>

            <div className={styles.filters}>
                <div className={styles.filterTitle} onClick={() => setOpenContent(!openContent)} >
                    <div className={styles.titleLeft}>
                        <i className='fa fa-filter fa-lg'></i>
                        <span>Filtreyi Kapat</span>
                    </div>
                    <i className={`fa fa-angle-${openContent ? 'up' : 'down'} fa-lg ${styles.toggleIcon}`}></i>
                </div>

                <div className={`${styles.filterContent} ${openContent ? '' : styles.hide}`}>
                    <div className={styles.filterItem}>
                        <input type='text' value={routeBarcode} onChange={(e: any) => setRouteBarcode(e.target.value)} placeholder='Güzergah Barkod' className='form-control' />
                    </div>

                    <div className={styles.filterItem}>
                        <div className='Güzergah Kodu / Adi'></div>
                        <Select isClearable={true}
                            options={voyages} className='col-12'
                            onChange={(data: any) => { setRouteId(data?.value ?? '') }}
                        />
                        {/* <input type='text' value={codeOrBarcode} onChange={(e: any) => setCodeOrBarcode(e.target.value)} placeholder='Güzergah Kodu / Adi' className='form-control' /> */}
                    </div>

                    <div className={styles.buttons}>
                        <button className='pretty-btn' onClick={reset} >Temizle</button>
                        <button className='pretty-btn btn-outline' onClick={filter}>Filtrele</button>
                    </div>


                </div>

            </div>


        </>
    )

}

export default LoadFilters;