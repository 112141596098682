import React, { useEffect } from 'react';
import styles from './footer.module.scss';
import Icon from '@mdi/react';
import { mdiAccountArrowLeft, mdiBarcodeScan, mdiCarPickup, mdiDropbox, mdiTruck } from '@mdi/js';
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
    const location = useLocation();

    const checkActivePath = (path) => {
        return location.pathname == path;
    }

    return (
        <>
            <div className={styles.footer}>

                <NavLink to={'/collection/assign'} className={`${styles.footerItem} ${checkActivePath('/collection/assign') ? styles.active : ''}`}>
                    <Icon path={mdiCarPickup}
                        title={mdiCarPickup}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>TOPLAMA</span>
                </NavLink>
                <NavLink to={'/collection'} className={`${styles.footerItem} ${checkActivePath('/collection') ? styles.active : ''}`}>
                    <Icon path={mdiBarcodeScan}
                        title={mdiBarcodeScan}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>SİPARİŞ</span>

                </NavLink>
                <NavLink to={'/distribution'} className={`${styles.footerItem} ${checkActivePath('/distribution') ? styles.active : ''}`}>
                    <Icon path={mdiDropbox}
                        title={mdiDropbox}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>DAĞITIM</span>
                </NavLink>
                <NavLink to={'/load-unload'} className={`${styles.footerItem} ${checkActivePath('/load-unload') ? styles.active : ''}`}>
                    <Icon path={mdiTruck}
                        title={mdiTruck}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>YÜKLEME</span>
                </NavLink>
                <NavLink to={'/unload'} className={`${styles.footerItem} ${checkActivePath('/unload') ? styles.active : ''}`}>
                    <Icon path={mdiTruck}
                        title={mdiTruck}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>İndirme</span>
                </NavLink>
                <NavLink to={'/embezzlement'} className={`${styles.footerItem} ${checkActivePath('/embezzlement') ? styles.active : ''}`}>
                    <Icon path={mdiAccountArrowLeft}
                        title={mdiAccountArrowLeft}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#FF8027"
                    // spin
                    />
                    <span>ZİMMET</span>
                </NavLink>
            </div>
        </>
    )

}

export default Footer;