import React, { useEffect, useRef, useState } from 'react';
import styles from './single-collection.module.scss';
import { IDistributionList } from '../../interfaces/distribution-list.interface';
import { IDistributionFilter } from '../../interfaces/distribution-filter.interface';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IDelivery } from '../../interfaces/delivery-params.interface';
import Header from '../../components/header/header';
import { batchAddOrder, getCarrierAssignmenWithAddress } from '../../services/pre-order.service';
import SingleCollectionItems from './single-collection-items/single-collection-items';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../store/actions';
import { toast } from 'react-toastify';


const SingleCollection = () => {

    const [list, setList] = useState<Array<any>>([])
    const [loader, setLoader] = useState<boolean>(true);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState<any[]>([]);
    const [addressId, setAddressId] = useState<string>('');
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const barcodeRef = useRef<any>();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();


    const getList = async (data?: any) => {
        setLoader(true);
        const addressId = String(params.addressId);
        setAddressId(addressId)
        const res = await getCarrierAssignmenWithAddress(addressId);
        setLoader(false);
        setList([...res?.items]);
    }


    useEffect(() => {
        getList();
    }, [])

    const batchOrder = async () => {
        dispatch(showLoading())
        const orders:any = [];
        selectedList?.map(item => {
            const params = {} as any;
            params.preOrderId = item.id;
            params.preOrderCode = item.code;
            params.senderCustomerName = item.senderName;
            params.preOrderBarcodes = [];
            params.isRequestFromMobile = true;
            params.isPNR = false;
            params.addPNROrderFromPNRPreOrder = null;
            item.barcodes.map(bItem => {
                const data = {} as any;
                data.no = bItem.code;
                data.senderAddressId = addressId;
                data.status = bItem.status;
                data.preOrderId = bItem.preOrderId;
                data.preOrderItemDetailId = bItem.preOrderItemDetailId;
                data.preOrderItemId = bItem.preOrderItemId;
                params.preOrderBarcodes.push(data)
            })
           orders.push(params)
        })

        const res = await batchAddOrder(orders);
        getList();
        dispatch(hideLoading())
        toast.success('Başarlı Teslim Alındı', {
            position: "bottom-center",
        });
    }

    const selectAllAction = (e) => {
        setSelectAll(e.target.checked)
    }


    return (
        <>

            <Header title="Toplama" />


            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>


            <div className={styles.button}>
                <button onClick={batchOrder} className='pretty-btn btn-outline'>
                    <a>Teslim Al</a>
                </button>
            </div>

            <div className={styles.group}>




                {/* <DistributionFilters onFilter={(data) => getDistributions(data)} /> */}

                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}

                <div className='d-flex gap-2 ms-3'>
                   <input type='checkbox' onClick={(e) => selectAllAction(e)} />
                    <span>Hepsini sec</span>
                </div>


                <SingleCollectionItems selectAll={selectAll} addressId={addressId} onSelectedList={(e: any) => {console.log(e); setSelectedList([...e])}} list={list} reload={() => getList()} />


            </div>

        </>
    )

}

export default SingleCollection;