const initialState = { 
    messaging: {}
  };

const FCMReducer = (state: any=initialState, action: any) => {
    switch (action?.type) {
        case 'SET_MESSAGING':
            {
                return {...state, messaging: action.payload};
            }
    }

    return state
}

export default FCMReducer;