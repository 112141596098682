import React, { useRef, useState } from "react";
import styles from './expertise.module.scss';
import { toast } from 'react-toastify';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import { getExpertizeDetailByBarcode, updateExpertize } from "../../services/expertise.service";
import Header from "../../components/header/header";
import { IExpertizeOrderDetail } from "./interfaces/expertize-order-detail";
import { IExpertizeRegisterOrderParmeters, IExpertizeRegisterOrderParmetersItem } from "./interfaces/expertize-register-order-parameters";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../store/actions";
import ExpertiseEstimates from "./expertize-estimates/expertize-estimates";
import Scanner from "../scanner/scanner";

const Expertise = () => {

    const barcodeRef = useRef<any>();
    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const [list, setList] = useState<IExpertizeOrderDetail[]>([])
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const dispatch = useDispatch();
    const [reset, setReset] = useState<boolean>(false);

    const search = async (barcode?: string) => {

        if (barcodeNumber || barcode) {
            dispatch(showLoading());
            const inputBarcode: any = barcode ? barcode : barcodeNumber;
            setScan(false);
            setLoader(true)
            const res = await getExpertizeDetailByBarcode(inputBarcode);
            dispatch(hideLoading());
            setLoader(false)
            const data = res.data;
            if (data && !data.message) {
                const index = list.findIndex(a => a.orderId == data.orderId);
                data.barcode = inputBarcode;
                data.showDetail = true;
                if (index == -1) {
                    const barcodeIndex = data.detail?.findIndex(a => a.barcode == inputBarcode);
                    if (barcodeIndex > -1) {
                        data.detail[barcodeIndex].active = true;
                    }
                    list.unshift(data);
                }
                else {
                    const barcodeIndex = list[index].detail?.findIndex(a => a.barcode == inputBarcode);
                    if (barcodeIndex > -1) {
                        list[index].detail[barcodeIndex].active = true;
                    }
                }
                setList([...list])
                audioSuccessRef.current.play();

            }
            else {
                audioErrorRef.current.play();
            }

            setBarcodeNumber('');
            barcodeRef.current?.focus();
            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }

    const changeValues = (listIndex, detailIndex, key, value) => {
        list[listIndex].detail[detailIndex][key] = value;
        setList([...list]);
    }


    const updateExpertizes = async (item: IExpertizeOrderDetail) => {
        const params = {} as IExpertizeRegisterOrderParmeters;
        params.items = [];
        item.detail.filter(a => a.active).map(detail => {
            const data = {} as IExpertizeRegisterOrderParmetersItem;
            data.barcode = detail.barcode;
            data.estimatedDesi = Number(detail.estimatedDesi);
            data.estimatedHeight = Number(detail.estimatedHeight);
            data.estimatedLength = Number(detail.estimatedLength);
            data.estimatedWeight = Number(detail.estimatedWeight);
            data.estimatedWidth = Number(detail.estimatedWidth);
            data.orderId = detail.orderId;
            data.id = detail.id;
            params.items.push(data);
        })
        dispatch(showLoading());
        const res = await updateExpertize(params);
        dispatch(hideLoading());

        if (res.data.success) {
            toast.success('Başarıyla Yapıldı', {
                position: "bottom-center",
            });
        }
    }


    const updateAllExpertizes = async () => {
        if (list.length > 0) {
            const params = {} as IExpertizeRegisterOrderParmeters;
            params.items = [];
            list.map(item => {
                item.detail.map(detail => {
                    const data = {} as IExpertizeRegisterOrderParmetersItem;
                    data.barcode = detail.barcode;
                    data.estimatedDesi = Number(detail.estimatedDesi);
                    data.estimatedHeight = Number(detail.estimatedHeight);
                    data.estimatedLength = Number(detail.estimatedLength);
                    data.estimatedWeight = Number(detail.estimatedWeight);
                    data.estimatedWidth = Number(detail.estimatedWidth);
                    data.orderId = detail.orderId;
                    data.id = detail.id;
                    params.items.push(data);
                })
            })

            dispatch(showLoading());
            const res = await updateExpertize(params);
            dispatch(hideLoading());
            if (res.data.success) {
                toast.success('Başarıyla Yapıldı', {
                    position: "bottom-center",
                });
                doReset();
            }
        }

    }

    const doReset = () => {
        setList([])
        setReset(true);
        barcodeRef.current?.focus();
    }
    const onEstimates = (e) => {
        list.map(item => {
            item.detail.filter(a => a.active).map(detail => {
                detail.estimatedDesi = e.estimatedDesi;
                detail.estimatedHeight = e.estimatedHeight;
                detail.estimatedLength = e.estimatedLength;
                detail.estimatedWeight = e.estimatedWeight;
                detail.estimatedWidth = e.estimatedWidth;
            })
        })

        setList([...list])
        setReset(false);
    }

    const toggleDetail = (item: IExpertizeOrderDetail, index: number) => {
        list[index].showDetail = !list[index].showDetail;
        setList([...list])
    }

    const toggleBarcodeDetail = (listIndex: number, itemIndex: number) => {
        list[listIndex].detail[itemIndex].showBarcodeDetail = !list[listIndex].detail?.[itemIndex]?.showBarcodeDetail
        setList([...list])
    }

    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }

    return (<>



        <audio controls ref={audioSuccessRef}>
            <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
        </audio>

        <audio controls ref={audioErrorRef}>
            <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
        </audio>


        <div className={styles.barcode}>
            <input
                readOnly={loader}
                ref={barcodeRef}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        search();
                    }
                }}
                value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
            <button onClick={() => search()} className={`${styles.ara} pretty-btn btn`} type='button'>Ara2</button>
            <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                <Icon path={mdiBarcode}
                    title={''}
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color={'black'}
                // spin
                />
            </button>


        </div >

        {scan ? (
            <div className='mt-2'>
                <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
            </div>
        ) : null}




        <div className={styles.pageList}>

            <ExpertiseEstimates reset={reset} exportEstimates={e => onEstimates(e)} updateAll={updateAllExpertizes} />

            <div className="list-container col-12 mt-2">





                {list?.map((item, listIndex) => {
                    return (


                        <div key={item.orderCode} className="col-12 border p-3 mt-3 bg-white" >
                            <div className="col-auto d-flex gap-2 align-items-center py-2 cursor-pointer" onClick={() => toggleDetail(item, listIndex)}>
                                <div className="col-auto d-flex gap-2">
                                    <span>Gönderi No:</span>
                                    <span>{item.orderCode}</span>
                                </div>
                                <i className={`fa fa-angle-${item.showDetail ? 'up' : 'down'} ms-auto me-1`} ></i>
                            </div>

                            {item.showDetail ? (

                                <>
                                    <div className={styles.info}>

                                        <div className="col-auto d-flex gap-2">
                                            <span>Oluşturulma Tarihi:</span>
                                            <span>{item.orderDate}</span>
                                        </div>
                                        <div className="col-auto d-flex gap-2">
                                            <span>ATF No:</span>
                                            <span>{item.receiptDocumentNumber}</span>
                                        </div>
                                        <div className="col-auto d-flex gap-2">
                                            <span>İrsaliye No:</span>
                                            <span>{item.shortConsigmentNumber}</span>
                                        </div>
                                    </div>



                                    {item.detail.map((detail, index) => {
                                        return (
                                            <div key={detail.barcode} className={styles.barcodes}>


                                                <div onClick={() => toggleBarcodeDetail(listIndex, index)} className="col-12 d-flex gap-2 justify-content-between align-items-center py-2 cursor-pointer">
                                                    <div className={`col-auto d-flex gap-2 text-sm  ${detail.active ? 'text-green' : ''} `}>
                                                        <span> Barkod No</span>
                                                        <span> {detail.barcode}</span>
                                                    </div>
                                                    <i className={`fa fa-angle-${detail.showBarcodeDetail ? 'up' : 'down'} ms-auto me-1`} ></i>
                                                </div>




                                                {detail.showBarcodeDetail ? (
                                                    <>
                                                        <div className={styles.item}>
                                                            <div className={styles.key}>
                                                                Desi
                                                            </div>
                                                            <div className={styles.value}>
                                                                <input className="form-control" value={detail.estimatedDesi} onChange={e => changeValues(listIndex, index, 'estimatedDesi', e.target.value)} />
                                                            </div>
                                                        </div>

                                                        <div className={styles.item}>
                                                            <div className={styles.key}>
                                                                Kg
                                                            </div>
                                                            <div className={styles.value}>
                                                                <input className="form-control" value={detail.estimatedWeight} onChange={e => changeValues(listIndex, index, 'estimatedWeight', e.target.value)} />
                                                            </div>
                                                        </div>


                                                        <div className={styles.item}>
                                                            <div className={styles.key}>
                                                                En
                                                            </div>
                                                            <div className={styles.value}>
                                                                <input className="form-control" value={detail.estimatedWidth} onChange={e => changeValues(listIndex, index, 'estimatedWidth', e.target.value)} />
                                                            </div>
                                                        </div>


                                                        <div className={styles.item}>
                                                            <div className={styles.key}>
                                                                Boy
                                                            </div>
                                                            <div className={styles.value}>
                                                                <input className="form-control" value={detail.estimatedHeight} onChange={e => changeValues(listIndex, index, 'estimatedHeight', e.target.value)} />
                                                            </div>
                                                        </div>

                                                        <div className={styles.item}>
                                                            <div className={styles.key}>
                                                                Yükseklik
                                                            </div>
                                                            <div className={styles.value}>
                                                                <input className="form-control" value={detail.estimatedLength} onChange={e => changeValues(listIndex, index, 'estimatedLength', e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null}

                                            </div>

                                        )
                                    })}







                                    <div className="col-12 d-flex align-items-center mt-3">
                                        <button type="button" className="btn  pretty-btn" onClick={() => updateExpertizes(item)} >Kaydet</button>
                                    </div>
                                </>

                            ) : null}

                        </div>






                    )
                })}

            </div>
        </div >


    </>)
}

export default Expertise;