import React, { useState } from 'react';
import SingleExpertize from './single-expertize/single-expertize';
import Header from '../../components/header/header';
import styles from './expertize-wrapper.module.scss';
import Expertise from './expertise';
const ExpertiseWrapper = () => {

    const [tabs, setTabs] = useState([{ id: 1, title: 'Tek', active: true }, { id: 2, title: 'Toplu' }])

    const [selectedId, setSelectedId] = useState<number>(1);


    const selectTab = (index) => {
        tabs.map(tab => tab.active = false)
        tabs[index].active = true;
        setTabs([...tabs])
        setSelectedId(tabs[index].id)
    }


    return (
        <>
            <Header title="Expertize" />
            <div className={styles.tabs}>
                {tabs.map((item, index) => {
                    return (
                        <div key={item.id} onClick={() => selectTab(index)} className={`${styles.tab} ${item.active ? styles.active : ''}`}>{item.title}</div>
                    )
                })}
            </div>
            <div className='page-content '>

                {selectedId == 1 ? (
                    <SingleExpertize />
                ) : (
                    <Expertise />
                )}


            </div>
        </>
    )
}

export default ExpertiseWrapper