
import axios from './../common/axios';
import { createHeaders, settings } from '../common/utils';

export const getPermissions = async () => {
    let headers: any = await createHeaders();
    let url: string = settings().baseUrl + "RolePermission/GetMobilePermissions";
    return axios.get(url, { headers: headers });
    //  return response?.data?.data;
    // await props.getMyCollectionsCounts();
    // await props.getMyDistributionCounts();
}