
import { useEffect, useRef, useState } from 'react';
import Header from '../../../components/header/header';
import { IAddVoyageList } from '../interfaces/add-voyage-list';
import styles from './add-voyage.module.scss';
import Select from 'react-select'
import { IAddVoyage, IAddVoyageDetail } from '../interfaces/add-voyage-params';
import SimpleReactValidator from 'simple-react-validator';
import { saveVoyage } from '../../../services/load-unload.service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr)

const AddVoyage = () => {

    const [voyageData, setVoyageData] = useState<IAddVoyageList>({} as IAddVoyageList);
    const [voyageDate, setVoyageDate] = useState<Date>(new Date());
    const [vehicleId, setVehicleId] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);

    const simpleValidator = useRef(new SimpleReactValidator({ autoForceUpdate: AddVoyage }))

    const navigate = useNavigate()

    const fromDateRef = useRef<any>();

    useEffect(() => {

        let storage = localStorage.getItem('voyage-data');
        if (storage) {
            const voyage: IAddVoyageList = JSON.parse(storage);
            console.log(voyage)
            setVoyageData({ ...voyage });
        }
    }, [])




    // useEffect(() => {
    //     simpleValidator.current.autoForceUpdate()
    // }, [voyageDate])
    const submit = async (e) => {
        setLoader(true)
        e.preventDefault();
        const params: IAddVoyage = {} as IAddVoyage;
        params.courseCodeAndName = voyageData.courseItems[0]?.code + '/' + voyageData.courseItems[0]?.name;
        params.courseId = voyageData.courseItems[0].id;
        params.voyageDate = moment(voyageDate).format('YYYY-MM-DD');
        params.firstBranchID = voyageData.courseItems[0].id;
        params.firstBranchCodeAndName = voyageData.courseItems[0]?.code + '/' + voyageData.courseItems[0]?.name;
        const vehicleIndex = voyageData.vehicleItems.findIndex(a => a.id == vehicleId);
        params.vehicleId = vehicleId;
        if (vehicleIndex > -1) {
            params.plateNumber = voyageData.vehicleItems[vehicleIndex].plateNumber;
        }
        params.details = [];
        voyageData.courseItems[0].details.map((item, index) => {
            const data = {} as IAddVoyageDetail;
            data.branchCodeAndName = item.startBranchCodeAndName;
            data.branchId = item.startBranchId;
            data.employeeId = item.driverId;
            params.details.push(data);
        })
        const res = await saveVoyage(params);
        toast.success('İşlem Başarılı.', {
            position: "bottom-center",
        })
        setLoader(false)
        localStorage.setItem('transfer', JSON.stringify(res.data.items[0]))
        navigate('/load')
    }



    const addDriverId = (driverId: string, index: number) => {
        voyageData.courseItems[0].details[index].driverId = driverId;
        setVoyageData({ ...voyageData })
    }
    return (
        <>

            <Header title="Sefer Oluştur" />

            {voyageData.courseItems ? (

                <div className='page-content'>
                    <form onSubmit={submit} >
                        <div className="add-voyage d-flex flex-column gap-3">
                            <div className="col-12 d-flex align-items-center gap-3">
                                <div className="col-4">
                                    Güzergah Kodu/Adı
                                </div>
                                <div className="col-8 pe-3">
                                    <textarea value={voyageData?.courseItems[0]?.code + '/' + voyageData.courseItems[0]?.name}
                                        className="form-control" readOnly disabled></textarea>
                                </div>
                            </div>


                            <div className="col-12 d-flex align-items-center gap-3">
                                <div className="col-4">
                                    Sefer Tarihi
                                </div>
                                <div className="col-8 pe-3">
                                    <DatePicker
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        scrollableYearDropdown={true}
                                        selected={voyageDate} onChange={(date) => setVoyageDate(date)}
                                        locale="tr"
                                    />
                                </div>
                                {simpleValidator.current.message('voyageDate', voyageDate, 'required')}

                            </div>


                            <div className="col-12 d-flex align-items-center gap-3">
                                <div className="col-4">
                                    Araç
                                </div>
                                <div className="col-8 pe-3">
                                    <Select isClearable={true}
                                        options={voyageData?.vehicleItems.map(item => {
                                            return {
                                                value: item.id,
                                                label: item.plateNumber
                                            }
                                        })}
                                        onChange={(data: any) => { setVehicleId(data?.value ?? '') }}
                                        className='col-12'
                                    />
                                </div>
                                {simpleValidator.current.message('vehicleId', vehicleId, 'required')}

                            </div>

                            <div className="col-12 d-flex align-items-center gap-3">
                                <div className="col-4">
                                    Başlangıç Noktası
                                </div>
                                <div className="col-8 pe-3">
                                    <input type="text" value={voyageData?.courseItems[0]?.startBranchCodeAndName} className="form-control"
                                        readOnly disabled />
                                </div>
                            </div>


                            <div className="col-12 d-flex flex-wrap align-items-center gap-3 mt-4">
                                <div className="col-12 fw-bold text-center">
                                    Şoför Bilgileri
                                </div>
                                <div className="col-12 pe-3  ">
                                    <table className="table ">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Durak Kodu / Adı
                                                </th>
                                                <th scope="col">
                                                    Sürücü Kod / Ad
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {voyageData.courseItems[0]?.details.map((item, index) => {
                                                return (
                                                    <tr >
                                                        <td scope="col">
                                                            {item.startBranchCodeAndName}
                                                        </td>
                                                        <td scope="col">
                                                            <div >
                                                                <Select isClearable={true}
                                                                    options={voyageData?.driverItems.map(item => {
                                                                        return {
                                                                            value: item.id,
                                                                            label: item.code + '/' + item.fullName
                                                                        }
                                                                    })}
                                                                    onChange={(data: any) => { addDriverId(data?.value, index) }}

                                                                    className='col-12'
                                                                />
                                                                {simpleValidator.current.message(`driverid_${index}`, item.driverId, 'required')}

                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div >
                            </div >


                            <div className="col-12 buttons d-flex align-items-center justify-content-center gap-3">
                                <button className="btn d-flex align-items-center justify-content-center gap-2" disabled={!simpleValidator.current.allValid()}>
                                    {loader ? (
                                        <div className='spinner-border text-light'></div>
                                    ) : null}
                                    Kaydet
                                </button>
                                <button className="btn   btn-outline" type="submit"  >Kapat </button>
                            </div>


                        </div>

                    </form>
                </div>
            ) : null}
        </>
    )


}

export default AddVoyage;