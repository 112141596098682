
import { useEffect, useState } from 'react';
import styles from './collection-info.module.scss';
import { ICollectionInfo } from '../../interfaces/collection-info.interface';
import { getCarrierAssignment } from '../../services/user.service';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/header/header';
const CollectionInfo = () => {

    const [info, setInfo] = useState<ICollectionInfo[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getCarrierInfo();
    }, [])
    const getCarrierInfo = async () => {
        const res = await getCarrierAssignment();
        setInfo([...res?.items]);
    }



    return (
        <>

            <Header title='Toplama' />
            <div className={styles.button}>
                <Link to="/collection/assign" className='pretty-btn btn-outline'>Gorevelerim</Link>
            </div>
            {info.map(item => {
                return (
                    <div className={styles.info}>
                        <div className={styles.item}>
                            <div className={styles.key}>Adres:</div>
                            <div className={styles.value}>{item.address}</div>
                        </div>
                        <div className={styles.items}>
                            <div className={styles.left}>
                                <div className={styles.item}>
                                    <div className={styles.key}>Gönderi Barkod:</div>
                                    <div className={styles.value}>{item.orderBarcodesCount}</div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.key}>Gönderi:</div>
                                    <div className={styles.value}>{item.ordersCount}</div>
                                </div>
                            </div>
                            <div className={styles.right}>
                                <div className={styles.item}>
                                    <div className={styles.key}>Sipariş barkod:</div>
                                    <div className={styles.value}>{item.preOrderBarcodesCount}</div>
                                </div>
                                <div className={styles.item}>
                                    <div className={styles.key}>Sipariş:</div>
                                    <div className={styles.value}>{item.preOrdersCount}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.button}>
                            <button className='btn pretty-btn' onClick={() => navigate(`/single-collection/${item.addressId}`) }>Teslim Al</button>
                        </div>


                    </div>

                )
            })}






        </>
    )

}

export default CollectionInfo;