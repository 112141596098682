export const statusCodeAndName = [
    { id: 0, text: "Silinmiş", code: "Deleted" },
    { id: 1, text: "Aktif", code: "Active" },
    { id: 2, text: "Planlanacak", code: "Requested" },
    { id: 3, text: "Alımı Yapıldı", code: "Collected" },
    { id: 4, text: "Gönderi Oluştu", code: "OrderCreated" },
    { id: 5, text: "Müşteri İptal", code: "CancelledByCustomer" },
    { id: 6, text: "IGC Red", code: "RejectedByPlanned" },
    { id: 7, text: "Eksik Gönderi Oluştu", code: "OrderWithMissingPart" },
    { id: 8, text: "Alımı Eksik Yapıldı", code: "CollectedWithMissingPart" },
    { id: 9, text: "Planlandı", code: "Planned" },
    { id: 10, text: "Güncellendi", code: "Modified" },
    { id: 11, text: "Kurye Atandı", code: "CourierAssigned" },
    { id: 12, text: "Ak (Aracı Kuruma) Atandı", code: "IntermediaryAssigned" },
    { id: 13, text: "AK Planlama", code: "IntermediaryToBePlanned" },
    { id: 14, text: "Alımı Yapılamadı", code: "NotCollected" },
    { id: 15, text: "Kurye Üzerinde", code: "InCourier" },
    { id: 16, text: "Teslim Edildi", code: "Delivered" },
    { id: 17, text: "Şubeden Teslim", code: "BranchDelivery" },
    { id: 18, text: "Bekleyen İade", code: "ExpectedReturn" },
    { id: 19, text: "İade Edildi", code: "Refunded" },
    { id: 20, text: "Pasif", code: "Passive" },
    { id: 21, text: "Dağıtım Planlama", code: "DistributionPlanning" },
    { id: 22, text: "Transfer Sürecinde", code: "InProcessOfTransfer" },
    { id: 23, text: "Transfer Merkezinde", code: "AtTransferCenter" },
    { id: 24, text: "Dağıtımda", code: "InDistribution" },
    { id: 25, text: "İade Kabul", code: "RefundAccepted" },
    { id: 26, text: "İmha", code: "Destruction" },
    { id: 27, text: "Kayıp", code: "Lost" },
    { id: 28, text: "Taslak", code: "Draft" },
    { id: 29, text: "", code: "" },
    { id: 30, text: "Dağıtım Tamamlandı", code: "DistributionCompleted" },
    { id: 31, text: "Taslak Fatura", code: "DraftInvoice" },
    { id: 32, text: "Kısmi Tahsilat", code: "PartialCollectionOfRevenues" },
    { id: 33, text: "Fatura Oluştu", code: "InvoiceOccurred" },
    { id: 34, text: "Tahsilat Edildi", code: "DoneCollectionORevenues" },
    { id: 35, text: "Kısmi Teslimat", code: "PartialDelivery" },
    { id: 36, text: "E-Finans`a Aktarılacak", code: "BeTransferredToEInvoice" },
    { id: 37, text: "E-Finans`a Aktarımı Yapılıyor", code: "Transferring" },
    { id: 38, text: "Aracı kuruma aktarılıyor", code: "ntermediaryTransferring" },
    { id: 39, text: "Aracı kuruma aktarıldı", code: "IntermediaryTransferred" },
    { id: 40, text: "Yüklenecek", code: "WillBeLoaded" },
    { id: 41, text: "Yüklendi", code: "Loaded" },
    { id: 42, text: "İndirildi", code: "Unloaded" },
    { id: 43, text: "Devir", code: "HandOver" },
];

export const orderStatusCodeAndName = [
    { id: 0, text: "Silinmiş", code: "Deleted" },
    { id: 4, text: "Gönderi oluştu", code: "OrderCreated" },
    { id: 5, text: "Müşteri iptal", code: "CancelledByCustomer" },
    { id: 15, text: "Kurye Üzerinde", code: "InCourier" },
    { id: 16, text: "Teslim Edildi", code: "Delivered" },
    { id: 18, text: "Bekleyen İade", code: "ExpectedReturn" },
    { id: 19, text: "İade Edildi", code: "Refunded" },
    { id: 21, text: "Planlanacak", code: "Requested" },
    { id: 22, text: "Transfer Sürecinde", code: "InProcessOfTransfer" },
    { id: 23, text: "Transfer Merkezinde", code: "AtTransferCenter" },
    { id: 24, text: "Kurye Üzerinde", code: "InDistribution" },
    { id: 26, text: "İmha", code: "Destruction" },
    { id: 27, text: "Kayıp", code: "Lost" },
    { id: 28, text: "Taslak", code: "Draft" },
    { id: 33, text: "Fatura Oluştu", code: "InvoiceOccurred" },
    { id: 35, text: "Kısmi Teslim Edildi", code: "PartialDelivery" },
    { id: 36, text: "Kurye Atandı", code: "CourierAssigned" },
    { id: 37, text: "AK Planlama", code: "IntermediaryRequested" },
    { id: 38, text: "AK Atandı", code: "IntermediaryAssigned" },
    { id: 40, text: "Yüklenecek", code: "WillBeLoaded" },
    { id: 41, text: "Yüklendi", code: "Loaded" },
    { id: 42, text: "İndirildi", code: "Unloaded" },
    { id: 43, text: "Devir", code: "HandOver" },
];