const SideMenuReducer = (state: boolean, action: any) => {
    switch (action?.type) {
        case 'OPEN_SIDE_MENU':
            {
                return true;
            }
        case 'CLOSE_SIDE_MENU':
            {
                return false;
            }
    }

    return false
}

export default SideMenuReducer;