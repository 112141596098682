import React, { useEffect, useRef, useState } from 'react';
import Login from './pages/login/login';
import Home from './pages/home/home';
import Barcode from './pages/barcode/barcode';
import Collection from './pages/collection/collection';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Scanner from './pages/scanner/scanner';
import Embezzlement from './pages/embezzlement/embezzlement';
import Distribution from './pages/distribution/distribution';
import Delivery from './pages/delivery/delivery';
import Return from './pages/return/return';
import CollectionInfo from './pages/collection-info/collection-info';
import Teslim from './pages/teslim/teslim';
import Footer from './components/footer/footer';
import styles from './App.module.scss';
import Header from './components/header/header';
import { AkbariSideMenu } from 'akbari-side-menu/dist/index';
import 'akbari-side-menu/dist/index.css';
import SideMenu from './components/side-menu/side-menu';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { closeSideMenuAction } from './store/actions';
import LoadUnload from './pages/load-unload/load-unload';
import VoyageDetail from './pages/load-unload/voyage-detail/voyage-detail';
import Load from './pages/load-unload/load/load';
import Loading from './components/loading/loading';
import AddVoyage from './pages/load-unload/add-voyage/add-voyage';
import UnLoad from './pages/load-unload/unload/unload';
import Expertise from './pages/expertise/expertise';
import { getCoords } from './common/utils';
import "react-datepicker/dist/react-datepicker.css";
import ExpertiseWrapper from './pages/expertise/exportize-wrapper';
import SingleCollection from './pages/single-collection/single-collection';

const App = (props: any) => {
  const location = useLocation();

  const sideRef = useRef<any>(null)
  const sideMenuStatus = useSelector((a: any) => a.sideMenuStatus);
  const dispatch = useDispatch();



  useEffect(() => {
    if (sideMenuStatus) {
      sideRef.current?.open();
    }
    else {
      sideRef.current?.close();
    }
  }, [sideMenuStatus])

  useEffect(() => {
    dispatch(closeSideMenuAction())
  }, [location.pathname])


  useEffect(() => {
    requestPermissions();
  }, [])


  const requestPermissions = async () => {
    getCoords()
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(stream => {
        stream.getTracks().forEach((track) => {
          if (track.readyState == 'live') {
            track.stop();
          }
        });
      })

  }



  return (
    <>


      <div className={styles.app}>

        <Routes>
          <Route path='' Component={Login} />
          <Route path='login' Component={Login} />
          <Route path='home' Component={Home} />
          <Route path='barcode' Component={Barcode} />
          <Route path='collection/:forceAssign?' Component={Collection} />
          <Route path='scanner' Component={Scanner} />
          <Route path='embezzlement' Component={Embezzlement} />
          <Route path='distribution' Component={Distribution} />
          <Route path='delivery/:type' Component={Delivery} />
          <Route path='return' Component={Return} />
          <Route path='collection-info' Component={CollectionInfo} />
          <Route path='teslim' Component={Teslim} />
          <Route path='load-unload' Component={LoadUnload} />
          <Route path='voyage-detail' Component={VoyageDetail} />
          <Route path='load' Component={Load} />
          <Route path='unload' Component={UnLoad} />
          <Route path='add-voyage' Component={AddVoyage} />
          <Route path='expertize' Component={ExpertiseWrapper} />
          <Route path='single-collection/:addressId' Component={SingleCollection} />


        </Routes>
      </div>


      <AkbariSideMenu
        ref={sideRef}
        onClose={() => dispatch(closeSideMenuAction())}
        pcWidth={50}
        mobileWidth={80}
        direction={'ltr'}
        showCloseButton={false}
      >

        <SideMenu />


      </AkbariSideMenu >

      <Loading />



      {!location.pathname.includes('login') && location.pathname != '' && location.pathname != '/' ? (
        <Footer />
      ) : null
      }







    </>
  );
};

export default App;