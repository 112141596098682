import React, { useRef, useState } from 'react';
import styles from './single-expertize.module.scss';
import Icon from '@mdi/react';
import { mdiBarcode } from '@mdi/js';
import { toast } from 'react-toastify';
import Scanner from './../../scanner/scanner';
import { Modal } from 'react-responsive-modal';
import { IExpertizeRegisterOrderParmeters, IExpertizeRegisterOrderParmetersItem } from '../interfaces/expertize-register-order-parameters';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/actions';
import { getExpertizeDetailByBarcode, updateExpertize } from '../../../services/expertise.service';
import { IExpertizeOrderDetail } from '../interfaces/expertize-order-detail';

const SingleExpertize = () => {

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [info, setInfo] = useState<IExpertizeOrderDetail>({} as IExpertizeOrderDetail)
    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const barcodeRef = useRef<any>();
    const [estimatedDesi, setEstimatedDesi] = useState<string>();
    const [estimatedWeight, setEstimatedWeight] = useState<string>();
    const [estimatedWidth, setEstimatedWidth] = useState<string>();
    const [estimatedHeight, setEstimatedHeight] = useState<string>();
    const [estimatedLength, setEstimatedLength] = useState<string>();
    const dispatch = useDispatch();


    const search = async (barcode?: string) => {

        if (barcodeNumber || barcode) {
            dispatch(showLoading());
            const inputBarcode: any = barcode ? barcode : barcodeNumber;
            if (!barcodeNumber) {
                setBarcodeNumber(inputBarcode);
            }
            setScan(false);
            setLoader(true)
            const res = await getExpertizeDetailByBarcode(inputBarcode);
            dispatch(hideLoading());
            setLoader(false)
            const data = res.data;
            if (data && !data?.message) {
                setInfo({ ...data })
                setOpenModal(true);
                const index = data?.detail?.findIndex(a => a.barcode == inputBarcode);
                if (index > -1) {
                    const detail = data?.detail?.[index];
                    setEstimatedDesi(detail.estimatedDesi)
                    setEstimatedWeight(detail.estimatedWeight)
                    setEstimatedWidth(detail.estimatedWidth)
                    setEstimatedHeight(detail.estimatedHeight)
                    setEstimatedLength(detail.estimatedLength)
                }
                audioSuccessRef.current.play();
            }
            else {
                audioErrorRef.current.play();
            }

            barcodeRef.current?.focus();
            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }



    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }


    const onCloseRemoveModal = () => {
        setOpenModal(false)
        setBarcodeNumber('');
    }


    const updateExpertizes = async () => {
        const params = {} as IExpertizeRegisterOrderParmeters;
        params.items = [];
        const index = info?.detail?.findIndex(a => a.barcode == barcodeNumber);
        if (index > -1) {
            const detail = info?.detail?.[index];
            const data = {} as IExpertizeRegisterOrderParmetersItem;
            data.barcode = detail.barcode;
            data.estimatedDesi = Number(estimatedDesi);
            data.estimatedHeight = Number(estimatedHeight);
            data.estimatedLength = Number(estimatedLength);
            data.estimatedWeight = Number(estimatedWeight);
            data.estimatedWidth = Number(estimatedWidth);
            data.orderId = detail.orderId;
            data.id = detail.id;
            params.items.push(data);
        }

        dispatch(showLoading());
        const res = await updateExpertize(params);
        dispatch(hideLoading());
        setOpenModal(false);
        resetEstimates();
        setBarcodeNumber('');
        if (res.data.success) {
            toast.success('Başarıyla Yapıldı', {
                position: "bottom-center",
            });
        }
    }

    const resetEstimates = () => {
        setEstimatedDesi('')
        setEstimatedWeight('')
        setEstimatedWidth('')
        setEstimatedHeight('')
        setEstimatedLength('')
    }


    return (
        <>


            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className={styles.barcode}>
                <input
                    readOnly={loader}
                    ref={barcodeRef}
                    onKeyDown={event => {
                        if (event.key === 'Enter') {
                            search();
                        }
                    }}
                    value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
                <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Ara2</button>
                <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                    <Icon path={mdiBarcode}
                        title={''}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color={'black'}
                    // spin
                    />
                </button>


            </div>



            {scan ? (
                <div className='mt-2'>
                    <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                </div>
            ) : null}


            <Modal
                classNames={{
                    modal: 'customModal',
                }}
                aria-labelledby="my-modal-title"
                open={openModal} onClose={onCloseRemoveModal} center>

                <div className={styles.items}>

                    <div className={styles.item}>
                        <div className={styles.key}>
                            Desi
                        </div>
                        <div className={styles.value}>
                            <input className="form-control" value={estimatedDesi} onChange={e => setEstimatedDesi(e.target.value)} />
                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.key}>
                            Kg
                        </div>
                        <div className={styles.value}>
                            <input className="form-control" value={estimatedWeight} onChange={e => setEstimatedWeight(e.target.value)} />
                        </div>
                    </div>


                    <div className={styles.item}>
                        <div className={styles.key}>
                            En
                        </div>
                        <div className={styles.value}>
                            <input className="form-control" value={estimatedWidth} onChange={e => setEstimatedWidth(e.target.value)} />
                        </div>
                    </div>


                    <div className={styles.item}>
                        <div className={styles.key}>
                            Boy
                        </div>
                        <div className={styles.value}>
                            <input className="form-control" value={estimatedHeight} onChange={e => setEstimatedHeight(e.target.value)} />
                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.key}>
                            Yükseklik
                        </div>
                        <div className={styles.value}>
                            <input className="form-control" value={estimatedLength} onChange={e => setEstimatedLength(e.target.value)} />
                        </div>
                    </div>


                    <div className="col-12 py-3 d-flex justify-content-between">
                        <button type="button" className="btn  btn-primary btn-sm" onClick={updateExpertizes} >Kaydet</button>

                    </div>



                </div>


            </Modal>



        </>
    )
}

export default SingleExpertize;