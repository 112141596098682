import React, { useEffect, useState } from 'react';
import Header from '../../../components/header/header';
import styles from './voyage-detail.module.scss';
import moment from 'moment'

const VoyageDetail = () => {

    const [voyage, setVoyage] = useState<any>({});

    useEffect(() => {
        const storage = JSON.parse(localStorage.getItem('voyage') as any)
        setVoyage({ ...storage })
    }, [])

    return (
        <>

            <Header title="Sefer Detay" />

            <div className={styles.box}>

                <div className={styles.boxTitle}>
                    Sefer Kodu: {voyage.code ? voyage.code : "-"}

                </div>
                <div className={styles.boxContent}>

                    <div className={styles.boxItem}>
                        <div className={styles.key}>Güzergah:</div>
                        <div className={styles.value}>
                            {voyage.courseCodeAndName}
                        </div>
                    </div>

                    <div className={styles.boxItem}>
                        <div className={styles.key}>Sefer Barkod No:</div>
                        <div className={styles.value}>
                            {voyage.code ? voyage.barcode : "-"}
                        </div>
                    </div>

                    <div className={styles.boxItem}>
                        <div className={styles.key}>Araç:</div>
                        <div className={styles.value}>
                            {voyage.plateNumber ? voyage.plateNumber : "-"}
                        </div>
                    </div>


                    <div className={styles.boxItem}>
                        <div className={styles.key}>Sefer Tarihi:</div>
                        <div className={styles.value}>
                            {voyage.voyageDate ? moment(voyage.voyageDate).format("DD.MM.YYYY HH:mm") : "-"}</div>
                    </div>






                </div>


            </div>


            <div className={styles.box}>

                <div className={styles.boxTitle}>
                    Operasyonları
                </div>
                <div className={styles.boxContent}>



                    <div className={styles.boxItems}>
                        <div className={styles.boxItemsTitle}>
                            Yükleme Operasyonları
                        </div>
                        {voyage?.loadVMs?.map(item => {
                            return (<>

                                <div className={styles.boxItem}>
                                    <div className={styles.key}>Gönderi No:</div>
                                    <div className={styles.value}>
                                        {item.orderCode}
                                    </div>
                                </div>

                                <div className={styles.boxItem}>
                                    <div className={styles.key}>İşlem Durumu:</div>
                                    <div className={styles.value}>
                                        {item.totalNumberOfPices}
                                        {" / "}
                                        {item.loadedPieces}
                                    </div>
                                </div>

                            </>)
                        })}
                    </div>


                    <div className={styles.boxItems}>
                        <div className={styles.boxItemsTitle}>
                            İndirme Operasyonları
                        </div>
                        {voyage?.unloadVMs?.map(item => {
                            return (<>

                                <div className={styles.boxItem}>
                                    <div className={styles.key}>Gönderi No:</div>
                                    <div className={styles.value}>
                                        {item.orderCode}
                                    </div>
                                </div>

                                <div className={styles.boxItem}>
                                    <div className={styles.key}>İşlem Durumu:</div>
                                    <div className={styles.value}>
                                        {item.totalNumberOfPices}
                                        {" / "}
                                        {item.unLoadedPieces}
                                    </div>
                                </div>

                            </>)
                        })}
                    </div>




                </div>


            </div>


        </>
    )
}

export default VoyageDetail;