import React, { useState } from 'react';
import { ITransferBarcode } from '../../../interfaces/transfer-barcode.interface';
import Styles from './barcode-list.module.scss';

type propsType = {
    barcodes: ITransferBarcode[]
}


const BarcodeList = (props: propsType) => {


    return (
        <>
            <div className={Styles.barcodes}>

                <table className="table table-striped ">
                    <thead>
                        <tr>
                            <th scope="col">
                                Barkod
                            </th>
                            <th scope="col">
                                Gönderi No
                            </th>
                            <th scope="col">
                                Alıcı
                            </th>
                            <th scope="col">
                                alıcı Şehir Adı
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {props.barcodes.map(item => {
                            return (
                                <tr key={item.barcode} className={item.isNew ? Styles.new : ''} >
                                    <td>{item.barcode}</td>
                                    <td>{item.orderCode}</td>
                                    <td>{item.receiverCustomerName}</td>
                                    <td>{item.receiverCityName}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
        </>
    )
}

export default BarcodeList;