import React, { useEffect, useRef, useState } from "react";
import styles from './expertize-estimates.module.scss';

const ExpertiseEstimates = (props: any) => {

    const [estimatedDesi, setEstimatedDesi] = useState<string>('');
    const [estimatedWeight, setEstimatedWeight] = useState<string>('');
    const [estimatedWidth, setEstimatedWidth] = useState<string>('');
    const [estimatedHeight, setEstimatedHeight] = useState<string>('');
    const [estimatedLength, setEstimatedLength] = useState<string>('');



    const exportEstimates = () => {
        const data = {
            estimatedDesi,
            estimatedWeight,
            estimatedWidth,
            estimatedHeight,
            estimatedLength
        }
        props.exportEstimates(data)
    }

    const updateAllExpertizes = () => {
        props.updateAll();
    }

    useEffect(() => {
        if (props.reset) {
            reset();
        }
    }, [props.reset])

    const reset = () => {
        console.log(1)
        setEstimatedDesi('');
        setEstimatedWeight('');
        setEstimatedWidth('');
        setEstimatedHeight('');
        setEstimatedLength('');
        const data = {
            estimatedDesi: '',
            estimatedWeight: '',
            estimatedWidth: '',
            estimatedHeight: '',
            estimatedLength: ''
        }
        props.exportEstimates(data)
    }

    return (
        <>

            <div className={styles.items}>



                <div className={styles.item}>
                    <div className={styles.key}>
                        Desi
                    </div>
                    <div className={styles.value}>
                        <input className="form-control" value={estimatedDesi} onChange={e => setEstimatedDesi(e.target.value)} />
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.key}>
                        Kg
                    </div>
                    <div className={styles.value}>
                        <input className="form-control" value={estimatedWeight} onChange={e => setEstimatedWeight(e.target.value)} />
                    </div>
                </div>


                <div className={styles.item}>
                    <div className={styles.key}>
                        En
                    </div>
                    <div className={styles.value}>
                        <input className="form-control" value={estimatedWidth} onChange={e => setEstimatedWidth(e.target.value)} />
                    </div>
                </div>


                <div className={styles.item}>
                    <div className={styles.key}>
                        Boy
                    </div>
                    <div className={styles.value}>
                        <input className="form-control" value={estimatedHeight} onChange={e => setEstimatedHeight(e.target.value)} />
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.key}>
                        Yükseklik
                    </div>
                    <div className={styles.value}>
                        <input className="form-control" value={estimatedLength} onChange={e => setEstimatedLength(e.target.value)} />
                    </div>
                </div>


                <div className="col-12 py-3 d-flex justify-content-between">
                    <button type="button" className="btn  btn-primary btn-sm" onClick={exportEstimates} >Tümünü Düzenle</button>
                    <button type="button" className="btn pretty-btn" onClick={updateAllExpertizes}  >Tümünü Kaydet</button>

                </div>



            </div>



        </>
    )

}

export default ExpertiseEstimates;