import React, { useEffect, useRef, useState } from 'react';
import styles from './load-unload.module.scss';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import Scanner from '../scanner/scanner';
import LoadItems from './load-items/load-items';
import LoadFilters from './load-filters/load-filters';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/header';
import { getListAddVoyage, getTransfers } from '../../services/load-unload.service';
import { ITransferParams } from '../../interfaces/transfer-params-interface';
import { ITransferList } from '../../interfaces/transfer-list.interface';


const LoadUnload = () => {

    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [list, setList] = useState<Array<ITransferList>>([]);
    const [loader, setLoader] = useState<boolean>(true);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const barcodeRef = useRef<any>();
    const navigate = useNavigate();

    const search = async (barcode?: string) => {
        const inputBarcode: any = barcode ? barcode : barcodeNumber;
        const params = {} as ITransferParams;
        params.routeBarcode = inputBarcode
        setScan(false);
        setLoader(true)
        let res = await getTransfers(params);
        setLoader(false)
        setList([]);
        if (res.items.length == 1) {
            getLoad(res.items[0])
        }
        else if (Array.isArray(res?.items) && res?.items.length > 0) {
            audioSuccessRef.current.play();
            setList([...res.items]);
        }
        else if (res?.items.length == 0) {
            findListAddVoyage(params);
        }
        setBarcodeNumber('');
        barcodeRef.current?.focus();
        setTimeout(() => {
            if (scanMode === 'camera') {
                setScan(true)
            }
        }, 2000)

    }

    const getTransferList = async (params: ITransferParams = {} as ITransferParams) => {

        setLoader(true);
        const res = await getTransfers(params);
        setLoader(false);
        setList([...res.items]);
        if (res.items.length == 1 && Object.keys(params).length > 0) {
            getLoad(res.items[0])
        }
        else if (res.items.length == 0) {
            findListAddVoyage(params);
        }
    }

    useEffect(() => {
        getTransferList();
    }, [])


    const getLoad = (item) => {
        localStorage.setItem('transfer', JSON.stringify(item))
        navigate('/load')
    }


    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }

    const findListAddVoyage = async (params: ITransferParams = {} as ITransferParams) => {
        const res = await getListAddVoyage(params.routeId, params.routeBarcode);
        if (res.data) {
            localStorage.setItem('voyage-data', JSON.stringify(res.data))
            navigate('/add-voyage')
        }

    }


    return (
        <>

            <Header title="Yükleme" />


            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className={styles.group}>


                <div className={styles.barcode}>
                    <input
                        ref={barcodeRef}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}
                        value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Güzergah Barkodu Girin' />
                    <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Sorgula</button>
                    <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                        <Icon path={mdiBarcode}
                            title={''}
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color={'black'}
                        />
                    </button>


                </div>




                {scan ? (
                    <div className={styles.video}>
                        <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                    </div>
                ) : null}



                <LoadFilters onFilter={(data) => getTransferList(data)} />

                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}


                <LoadItems list={list} />


            </div>

        </>
    )

}

export default LoadUnload;