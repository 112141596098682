import { IEmbezzlementSearch } from "../interfaces/embezzlement-search.interface";
import { settings, createHeaders } from '../common/utils';
import axios from './../common/axios';
import { IEmbezzlementList } from "../interfaces/embezzlement-list.interface";


export const getEmbezzlement = async (barcode: string): Promise<any> => {
    let url: string = settings().baseUrl + 'Embezzlement/GetMyEmbezzlements';
    let headers = await createHeaders();
    let data = { barcodeNo: barcode };
    return await axios.post(url, data, { headers: headers });
}


export const EmbezzlementOfBarcode = async (model: Array<IEmbezzlementList>): Promise<any> => {
    let url: string =
        settings().baseUrl + 'Embezzlement/EmbezzlementOfBarcodes';
    let headers = await createHeaders();
    let data = [
        {
            barcodes: [model[0].barcodeNo],
            barcodeNo: model[0].orderCode,
            orderCode: model[0].orderCode,
            desiKg: model[0].desiKg,
            orderIndex: model[0].orderIndex,
            packageType: model[0].packageType,
        },
    ];

    return await axios.post(url, data, { headers: headers });

}