import React, { useEffect, useRef, useState } from 'react';
import styles from './dstribution.module.scss';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import Scanner from '../scanner/scanner';
import DistributionHeader from './distribution-header/distribution-header';
import DistributionItems from './distribution-items/distribution-items';
import { getDistributionPlan, getDistributionPlansFilter } from '../../services/distribution.service';
import { IDistributionList } from '../../interfaces/distribution-list.interface';
import DistributionFilters from './distribution-filters/distributions-filters';
import { IDistributionFilter } from '../../interfaces/distribution-filter.interface';
import { Link, useNavigate } from 'react-router-dom';
import { IDelivery } from '../../interfaces/delivery-params.interface';
import Header from '../../components/header/header';


const Distribution = () => {
    const [barcodeNumber, setBarcodeNumber] = useState<string>('');
    const [list, setList] = useState<Array<IDistributionList>>([])
    const [loader, setLoader] = useState<boolean>(true);
    const [scanMode, setScanMode] = useState<string>('manual');
    const [scan, setScan] = useState<boolean>(false);
    const [selectedList, setSelectedList] = useState<IDelivery[]>([]);
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const barcodeRef = useRef<any>();
    const navigate = useNavigate();

    const search = async (barcode?: string) => {


        if (barcodeNumber || barcode) {
            const inputBarcode: any = barcode ? barcode : barcodeNumber;
            setScan(false);
            setLoader(true)
            const res = await getDistributionPlan(inputBarcode);
            setLoader(false)
            const data = res.data;
            setList([...res.data]);
            if (data && data.length > 0) {

            }
            else {
                audioErrorRef.current.play();
                toast.error(res.message, {
                    position: "bottom-center",
                });

            }

            setBarcodeNumber('');
            barcodeRef.current?.focus();

            setTimeout(() => {
                if (scanMode === 'camera') {
                    setScan(true)
                }
            }, 2000)
        }
        else {
        }
    }


    const getDistributions = async (data?: IDistributionFilter) => {
        setLoader(true);
        const res = await getDistributionPlansFilter(data);
        setLoader(false);
        setList([...res.data]);

    }


    useEffect(() => {
        getDistributions();
    }, [])





    const onScannerSuccess = (barcode: string) => {
        setBarcodeNumber(barcode);
        search(barcode);
        setScan(false);
    }

    const goDelivery = () => {
        navigate('/teslim')
    }


    return (
        <>

            <Header title="Dağıtım" />


            <audio controls ref={audioSuccessRef}>
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef}>
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>


            <div className={styles.button}>
                <button onClick={goDelivery} className='pretty-btn btn-outline'>
                    <a>Teslim</a>
                </button>
            </div>

            <div className={styles.group}>


                <div className={styles.barcode}>
                    <input
                        ref={barcodeRef}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                search();
                            }
                        }}
                        value={barcodeNumber} onChange={(e: any) => setBarcodeNumber(e.target.value)} type="text" placeholder='Barkod No Giriniz' />
                    <button onClick={() => search()} className={`${styles.ara} pretty-btn`} type='button'>Ara2</button>
                    <button onClick={() => { setScan(!scan); setScanMode('camera') }} className={`${styles.barcodeImage} btn btn-outline`} type='button'>
                        <Icon path={mdiBarcode}
                            title={''}
                            size={1}
                            horizontal
                            vertical
                            rotate={180}
                            color={'black'}
                        // spin
                        />
                    </button>


                </div>




                {scan ? (
                    <div className={styles.video}>
                        <Scanner onClose={() => { setScan(false); setScanMode('manaul') }} scan={scan} onData={(e) => { onScannerSuccess(e); setScanMode('camera') }} />
                    </div>
                ) : null}



                <DistributionFilters onFilter={(data) => getDistributions(data)} />

                {loader ? (
                    <div className={styles.loader}>
                        <div className='spinner-border text-danger'></div>
                    </div>
                ) : null}


                <DistributionItems onSelectedList={(e: Array<IDelivery>) => setSelectedList([...e])} list={list} reload={() => getDistributions()} />


            </div>

        </>
    )

}

export default Distribution;