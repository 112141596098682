import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styles from './login.module.scss';
import { loginService } from './../../services/user.service';
import { IUser } from '../../interfaces/user.interface';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../store/actions';
import {setMessagingAction} from '../../store/actions/index';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [loader, setLoader] = useState<boolean>(false);
    const dispatch = useDispatch();



    const login = async (e: any) => {
        setLoader(true)
        e.preventDefault();
        const params: IUser = {} as IUser;
        params.username = username;
        params.password = password;
        try {
            const {res, messaging}: any = await loginService(params);

            dispatch(setMessagingAction(messaging))

            setLoader(false)
            if (res.data.accessToken) {
                localStorage.setItem('token', res.data.accessToken)
                var decoded = jwt_decode(res.data.accessToken);
                localStorage.setItem('user', JSON.stringify(decoded))
                dispatch(loginAction())
                navigate('/distribution');
            }
        }
        catch (e) {
            const {res, messaging}: any = await loginService(params);

            dispatch(setMessagingAction(messaging))

            setLoader(false)
            if (res.data.accessToken) {
                localStorage.setItem('token', res.data.accessToken)
                var decoded = jwt_decode(res.data.accessToken);
                localStorage.setItem('user', JSON.stringify(decoded))
                dispatch(loginAction())
                navigate('/distribution');

            }
        }
    }

    useEffect(() => {
    }, [])


    return (
        <>
            <div className={styles.login}>
                <form onSubmit={login}>


                    <div className={styles.image}>
                        <img alt='logo' src='./assets/images/logo-igc-mor.png' />
                    </div>

                    <div className={styles.input}>
                        <div className={styles.inputTitle}>E-Posta</div>
                        <input
                            type='text'
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>

                    <div className={styles.input}>
                        <div className={styles.inputTitle}>Şifre</div>
                        <input
                            type='password'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className={styles.button}>
                        <button className='pretty-btn' type='submit' >
                            Giriş Yap
                            {loader ? (
                                <div className='spinner-border text-light'></div>
                            ) : null}
                        </button>
                    </div>
                </form>

            </div>
        </>
    )

}

export default Login;