

import { settings, createHeaders } from '../common/utils';
import axios from './../common/axios';


export const getWithBarcodeNumber = async (barcodeNumber: string, forceAssign: boolean = false): Promise<any> => {
    let url: string =
        settings().baseUrl +
        `PreOrder/GetWithBarcodeNo?barcodeNo=${barcodeNumber}&forceAssign=${forceAssign} `;
    let headers = await createHeaders();
    let response = await axios.post(url, {}, { headers: headers });
    return response;
}



export const batchAddOrder = async (model): Promise<any> => {
    model.isRequestFromMobile = true;
    let url: string = settings().baseUrl + "PreOrder/BatchAddOrderFromBody";
    let headers = await createHeaders();
    return await axios.post(url, model, { headers: headers });
}


export const getCarrierAssignmenWithAddress = async (addressId: string): Promise<any> => {
    let url: string = settings().serviceUrl + "carrier/assignment-preorder-summery?addressId="+ addressId;
    let headers = await createHeaders();
    const res = await axios.get(url, { headers: headers });
    return res.data;
}

