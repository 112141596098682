import React, { useEffect, useRef, useState } from 'react';
import Styles from './unload.module.scss'
import Header from '../../../components/header/header';
import BarcodeList from '../barcode-list/barcode-list';
import { ITransferBarcode } from '../../../interfaces/transfer-barcode.interface';
import { useNavigate } from 'react-router-dom';
import { mdiBarcode } from '@mdi/js';
import Icon from '@mdi/react';
import Scanner from '../../scanner/scanner';
import { toast } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { IRoom } from '../interfaces/room';
import { getRoomByBarcode } from '../../../services/load-unload.service';
import Swal from 'sweetalert2'


const UnLoad = () => {
    const [unloadedBarcodes, setUnloadedBarcodes] = useState<ITransferBarcode[]>([]);
    const unloadedBarcodesRef = useRef<ITransferBarcode[]>([]);
    const [shouldUnloadBarcodes, setShouldUnloadBarcodes] = useState<ITransferBarcode[]>([]);
    const shouldUnloadBarcodesRef = useRef<ITransferBarcode[]>([]);

    const [room, setRoom] = useState<IRoom>({} as IRoom);
    const [scan, setScan] = useState<boolean>(false);
    const [roomScan, setRoomScan] = useState<boolean>(false);
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const [openPointModal, setOpenPointModal] = useState<boolean>(true);
    const [barcode, setBarcode] = useState('')
    const [removeBarcode, setRemoveBarcode] = useState<string>('')
    const [roomBarcode, setRoomBarcode] = useState<string>('')
    const [roomLoader, setRoomLoader] = useState<boolean>(false)
    const barcodeRef = useRef<any>('')
    const roomBarcodeRef = useRef<any>('')
    const [loading, setLoading] = useState<boolean>(false);

    const socket = useRef<any>();
    const audioSuccessRef = useRef<any>();
    const audioErrorRef = useRef<any>();
    const navigate = useNavigate();


    useEffect(() => {
        return () => {
            closeSocket();
        }
    }, [])




    const getRoom = async (_barcode?: string) => {
        const inputBarcode: any = _barcode ? _barcode : roomBarcode;
        setRoomLoader(true);
        const res: IRoom = await getRoomByBarcode(inputBarcode);
        setRoom({ ...res });
        setOpenPointModal(false);
        setRoomLoader(false);
    }

    const closeSocket = () => {
        const closeStream = { "arguments": [room.id], "target": "UnSubscribeStream", "type": 1 }
        if (socket.current) {
            sendMessage(closeStream);
            socket.current.close();
        }
    }

    useEffect(() => {
        if (room.id) {
            barcodeRef.current.focus()
            loadSocket();
        }
    }, [room])



    const loadSocket = () => {
        const token = localStorage.getItem('token')
        socket.current = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}MainHub?access_token=${token}`)
        socket.current.onopen = () => {
            console.log('opended')
            const firstMessage = { "protocol": "json", "version": 1 }
            sendMessage(firstMessage)
            const loadMessage = { "arguments": [room.id], "target": "SubscribeRoomStream", "type": 1 }
            sendMessage(loadMessage)
        }
        socket.current.onerror = (e: any) => {
            console.log('error', e)
        }

        socket.current.onmessage = (data: any) => {
            const message = JSON.parse(data.data.replace('', ''));
            if(message?.arguments?.[0].subscriptionId == room.id){
                setLoading(false)
            }
            if (message?.arguments && message.arguments.length > 0 && !message?.arguments[0]?.success) {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: "btn",
                        cancelButton: "btn btn-danger"
                    },
                    buttonsStyling: false
                });
                swalWithBootstrapButtons.fire({
                    icon: "error",
                    title: "Hata...",
                    text: message.arguments[0].message,
                    confirmButtonText: "Tamam",
                    confirmButtonColor: "#fd7e14",
                });
                playSound('errorSound')
            }
            else if (message.arguments && message.arguments.length > 0 && message.arguments[0].subscriptionId == room.id) {

                if (message.target == 'BarcodeUnloadedToRoom') {
                    setUnloadedBarcodes([...message.arguments[0].data]);
                    unloadedBarcodesRef.current = message.arguments[0].data
                }
                else if (message.target == 'BarcodeShouldBeUnloadToRoom') {
                    setShouldUnloadBarcodes([...message.arguments[0].data]);
                    shouldUnloadBarcodesRef.current = message.arguments[0].data;
                }

                else if (message.target == 'BarcodeShouldBeUnloadAdd') {
                    const barcodeShouldAdd: ITransferBarcode[] = message.arguments[0].data;
                    barcodeShouldAdd.map(item => {
                        const index = shouldUnloadBarcodesRef.current.findIndex(a => a.barcode == item.barcode);
                        if (index == -1) {
                            item.isNew = true
                            shouldUnloadBarcodesRef.current.unshift(item);
                        }
                    })
                    setShouldUnloadBarcodes([...shouldUnloadBarcodesRef.current])

                    setTimeout(() => {
                        shouldUnloadBarcodesRef.current.map(item => item.isNew = false)
                        setShouldUnloadBarcodes([...shouldUnloadBarcodesRef.current])
                    }, 1000)
                }
                else if (message.target == 'ABarcodeUnloadedToRoom') {
                    const loadedBarcode: ITransferBarcode = message.arguments[0].data;

                    ///remove from shoudloadedbarcodes 
                    const shouldUnloadLoadedIndex = shouldUnloadBarcodesRef.current.findIndex(a => a.barcode == loadedBarcode.barcode)
                    if (shouldUnloadLoadedIndex > -1) {
                        shouldUnloadBarcodesRef.current.splice(shouldUnloadLoadedIndex, 1)
                        setShouldUnloadBarcodes([...shouldUnloadBarcodesRef.current])
                    }

                    const index = unloadedBarcodesRef.current.findIndex(a => a.barcode == loadedBarcode.barcode);
                    if (index == -1) {
                        unloadedBarcodesRef.current.map(item => item.isNew = false)
                        loadedBarcode.isNew = true;
                        unloadedBarcodesRef.current.unshift(loadedBarcode)
                        setUnloadedBarcodes([...unloadedBarcodesRef.current])
                        playSound('successSound')
                    }
                    else {
                        playSound('errorSound')
                    }
                    setTimeout(() => {
                        unloadedBarcodesRef.current.map(item => item.isNew = false);
                        setUnloadedBarcodes([...unloadedBarcodesRef.current])
                    }, 1000)
                }
            }

        }
    }




    const sendMessage = (message: any) => {
        socket.current.send(JSON.stringify(message) + '')
    }

    const playSound = (id: string) => {
        (document.getElementById(id) as HTMLAudioElement).pause();
        (document.getElementById(id) as HTMLAudioElement).currentTime = 0;
        (document.getElementById(id) as HTMLAudioElement).play();
    }


    const unload = (_barcode?: string) => {
        const inputBarcode: any = _barcode ? _barcode : barcode;

        if (inputBarcode == 'k001') {
            reset();
        }
        const loadMessage = { "arguments": [room.id, inputBarcode], "target": "unLoad", "type": 1 }
        sendMessage(loadMessage);
        setBarcode('')
        const focus = (document.getElementById('barcode') as HTMLInputElement).focus();
        setLoading(true)
    }


    const reset = () => {
        setUnloadedBarcodes([])
        unloadedBarcodesRef.current = [];
        setShouldUnloadBarcodes([])
        shouldUnloadBarcodesRef.current = [];
        closeSocket();
        setRoomBarcode('');
        setOpenPointModal(true);
    }


    const onScannerSuccess = (_barcode: string) => {
        setBarcode(_barcode);
        unload(_barcode);
        setScan(false);
    }

    const onRoomScannerSuccess = (_barcode: string) => {
        setRoomBarcode(_barcode);
        getRoom(_barcode)
        setRoomScan(false);
    }


    const undoUnload = () => {
        if (removeBarcode) {
            if (barcode == 'k001') {
                reset();
            }
            const loadMessage = { "arguments": [room.id, removeBarcode], "target": "undoUnLoad", "type": 1 }
            sendMessage(loadMessage);
            setOpenRemoveModal(false)
        }

    }


    const onCloseRemoveModal = () => {
        setOpenRemoveModal(false)
    }

    const onOpenRemoveModal = () => {
        setRemoveBarcode('');
        setOpenRemoveModal(true)
    }


    const calculatePercentOfUnloadedBarcodes = () => {
        return (unloadedBarcodes.length / (shouldUnloadBarcodes.length +
            unloadedBarcodes.length) * 100)
    }


    return (
        <>

            <Header title="İndirme" />

            <audio controls ref={audioSuccessRef} id="successSound">
                <source src="./assets/sound/Success.mp3" type="audio/mpeg" />
            </audio>

            <audio controls ref={audioErrorRef} id="errorSound">
                <source src="./assets/sound/Error.mp3" type="audio/mpeg" />
            </audio>

            <div className='page-content '>



                <div className={Styles.transferStatistics}>

                    <div className={Styles.items}>
                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                İndirmesi Gereken Parça Adedi
                            </div>

                            <div className={Styles.value}>{shouldUnloadBarcodes.length}</div>

                        </div>


                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                İndirenen Parça Adedi
                            </div>
                            <div className={Styles.value}>{unloadedBarcodes.length}</div>
                        </div>

                        <div className={Styles.item}>
                            <div className={Styles.key}>
                                İndirme Tamamlanma Oranı
                            </div>
                            <div className={`progress col-12 ${Styles.progressBar}`}>
                                <div className="progress-bar progress-bar-animated progress-bar-striped bg-success" role="progressbar" style={{ width: `${calculatePercentOfUnloadedBarcodes()}%` }} ></div>
                            </div>
                            {!isNaN(calculatePercentOfUnloadedBarcodes()) ? (
                                <div className={Styles.value}>

                                    {calculatePercentOfUnloadedBarcodes().toFixed(0)}%
                                </div>
                            ) : 0}


                        </div>
                    </div>




                </div>

                <div className={Styles.title}>
                    Kargo Bilgileri - <span className="text-primary ms-2">İndirme</span> - <span className="text-primary ms-2">{room.name}</span>
                </div>
                <div className={Styles.barcodePart}>


                    <div className={Styles.form}>
                        <div className={Styles.input}>
                            <div className="label">Barkod:</div>
                            <input
                                readOnly={loading}
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        unload();
                                    }
                                }}
                                id="barcode" value={barcode} ref={barcodeRef} onChange={e => setBarcode(e.target.value)} className="form-control" />
                            <div className="btn-group">
                                <button className="btn btn-sm" type="submit" onClick={() => unload()}>İndir</button>
                                <button className="btn btn-sm" type="submit" onClick={() => onOpenRemoveModal()}>Sill</button>
                                <button onClick={() => { setScan(!scan) }} className={`${Styles.barcodeImage} btn btn-sm btn-outline`} type='button'>
                                    <Icon path={mdiBarcode}
                                        title={''}
                                        size={1}
                                        horizontal
                                        vertical
                                        rotate={180}
                                        color={'black'}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>


                </div>

                {/* {scan ? (
                    <div className={Styles.video}>
                        <Scanner onClose={() => { setScan(false) }} scan={scan} onData={(e) => { onScannerSuccess(e) }} />
                    </div>
                ) : null} */}


                {scan ? (
                    <div className={Styles.video}>
                        <Scanner onClose={() => { setScan(false) }} scan={scan} onData={(e) => { onScannerSuccess(e) }} />
                    </div>
                ) : null}


                {roomScan ? (
                    <div className={Styles.video}>
                        <Scanner fullWidth={true} onClose={() => { setRoomScan(false) }} scan={roomScan} onData={(e) => { onRoomScannerSuccess(e) }} />
                    </div>
                ) : null}

                <div className={Styles.box}>
                    <div className={`${Styles.items} box-item`}>
                        <div className={Styles.itemTitle}>İndirenecek Barkodlar</div>
                        <div className={Styles.itemContent}>
                            <BarcodeList barcodes={shouldUnloadBarcodes} />
                        </div>
                    </div>
                    <div className={`${Styles.items} box-item`}>
                        <div className={Styles.itemTitle}>İndirilen Barkodlar</div>
                        <div className={Styles.itemContent}>
                            <BarcodeList barcodes={unloadedBarcodes} />
                        </div>
                    </div>
                </div>



                <Modal
                    classNames={{
                        modal: 'customModal',
                    }}

                    aria-labelledby="my-modal-title"
                    open={openPointModal} showCloseIcon={false} closeOnOverlayClick={false} onClose={() => { setOpenPointModal(false) }} center>
                    <div className='col-12 d-flex align-items-center mt-4' >
                        <div className='col-3'>Barkod</div>
                        <div className='col-9 d-flex gap-2'>
                            <input
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        getRoom();
                                    }
                                }}
                                ref={roomBarcodeRef}
                                type='text' className='form-control' value={roomBarcode} onChange={e => setRoomBarcode(e.target.value)} />
                            <button onClick={() => { setRoomScan(!roomScan) }} className={`${Styles.barcodeImage} btn btn-sm btn-outline`} type='button'>
                                <Icon path={mdiBarcode}
                                    title={''}
                                    size={1}
                                    horizontal
                                    vertical
                                    rotate={180}
                                    color={'black'}
                                />
                            </button>

                        </div>
                    </div>
                    <div className='col-12 d-flex mt-4 justify-content-center'>
                        <button className='pretty-btn btn-danger d-flex align-items-center justify-content-center gap-2' onClick={() => getRoom()}>
                            Kaydet
                            {roomLoader ? (
                                <div className="spinner-border spinner-border-sm text-light"></div>
                            ) : null}
                        </button>
                    </div>
                </Modal>


                <Modal
                    classNames={{
                        modal: 'customModal',
                    }}
                    aria-labelledby="my-modal-title"
                    open={openRemoveModal} onClose={onCloseRemoveModal} center>
                    <h2 id="my-modal-title">Sill</h2>

                    <div className='col-12 d-flex align-items-center mt-4' >
                        <div className='col-3'>Barkod</div>
                        <div className='col-9'>
                            <input
                                onKeyDown={event => {
                                    if (event.key === 'Enter') {
                                        undoUnload();
                                    }
                                }}
                                type='text' className='form-control' value={removeBarcode} onChange={e => setRemoveBarcode(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-12 d-flex mt-4 justify-content-center'>
                        <button className='pretty-btn ' disabled={!removeBarcode} onClick={undoUnload}>Sill</button>
                    </div>
                </Modal>



            </div >
        </>
    )

}


export default UnLoad;