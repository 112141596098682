import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { getCoords } from '../../../common/utils';
import styles from './delivery.module.scss'


function DeliveryMap(props) {
    const google = window.google;
    const markers = useRef<Array<any>>([]);
    const map = useRef<google.maps.Map>();

    useEffect(() => {
        initMap();
    }, [])


    const initMap = async () => {
        const coords: any = await getCoords();
        // The location of Uluru
        sendLocation(coords.lat, coords.long)
        var mapProp = {
            center: new google.maps.LatLng(coords.lat, coords.long),
            zoom: 15,
        };
        map.current = new google.maps.Map(document.getElementById("map") as HTMLElement, mapProp);
        const marker = new google.maps.Marker({ position: new google.maps.LatLng(coords.lat, coords.long) });
        marker.setMap(map.current);
        markers.current.push(marker)
        google.maps.event.addListener(map.current, 'click', function (event) {
            placeMarker(map.current, event.latLng);
        });
    }

    const placeMarker = (map, location) => {
        deleteMarkers();
        var marker = new google.maps.Marker({
            position: location,
            map: map
        });
        markers.current.push(marker)
        sendLocation(location.lat(), location.lng())
    }

    // Sets the map on all markers in the array.
    const setMapOnAll = (map: google.maps.Map | null) => {
        for (let i = 0; i < markers.current.length; i++) {
            markers.current[i].setMap(map);
        }
    }

    const deleteMarkers = () => {
        setMapOnAll(null);
        markers.current = [];
    }


    const sendLocation = (lat, long) => {
        props.onLocationChange({ lat, long })
    }



    return (
        <>
            <div className={styles.map} id='map'></div>
        </>
    )

}

export default (DeliveryMap)